import { AdapterBase } from '@utilities/adapter-base';
import { BorrowerApi } from '@models/api/borrower';
import { BorrowerForm } from '@models/form/borrower.model';
import { OrdinalBorrowerNames } from '@lookups/ordinal-borrower-names';
import { EmailApi } from '@models/api/email';
import { PhoneApi } from '@models/api/phone';
import _ from 'lodash';
import { DateHelper } from '@utilities/helpers';
import { Constants } from '@utilities/constants';

export class BorrowerApiToBorrowerFormAdapter extends AdapterBase<BorrowerApi, BorrowerForm> {
	public adapt(source: BorrowerApi): BorrowerForm {
		const myBorrower = new BorrowerForm();
		myBorrower.index = source.BorrowerIndex;
		myBorrower.firstName = source.FirstName;
		myBorrower.lastName = source.LastName;
		myBorrower.middleInitial = source.MiddleName;
		myBorrower.suffix = source.Suffix; 
		if (source.BorrowerIndex >= 0 && source.BorrowerIndex < OrdinalBorrowerNames.length) {
			myBorrower.emptyName = OrdinalBorrowerNames[source.BorrowerIndex];
		}
		if (source.TaxIdentificationIdentifier) {
			myBorrower.maskedSsn = Constants.SsnMaskValues.SsnMasked;
			myBorrower.verifyMaskedSsn = Constants.SsnMaskValues.SsnMasked;
			myBorrower.verifySsn = source.TaxIdentificationIdentifier;
			myBorrower.ssn = source.TaxIdentificationIdentifier;
		}
		myBorrower.maritalStatus = source.MaritalStatus || null;
		myBorrower.primaryPhone = this.getPrimaryPhone(source.Phones);
		myBorrower.email = this.getPrimaryEmail(source.Emails);
		myBorrower.birthDate = DateHelper.dateToString(source.BirthDate);
		if (source.MilitaryService) {
			myBorrower.militarySelfDeclaredService = source.MilitaryService.SelfDeclaredMilitaryService;
			myBorrower.militaryActiveDuty = source.MilitaryService.ActiveDuty;
			myBorrower.militaryExpectedCompletionDate = DateHelper.dateToString(source.MilitaryService.ExpectedCompletionDate);
			myBorrower.militaryVeteran = source.MilitaryService.Veteran;
			myBorrower.militaryReserveNationalGuardReserveActivated = source.MilitaryService.ReserveNationalGuardReserveActivated;
			myBorrower.militarySurvivingSpouse = source.MilitaryService.SurvivingSpouse;
		}
		myBorrower.haveEmployers = source.HaveEmployers;
		myBorrower.depCount = source.DependentCount || 0;
		myBorrower.depAges = source.DependentAges ? _.map(source.DependentAges.split(','), (value) => ({ age: value })) : [];
		return myBorrower;
	}

	private getPrimaryEmail(emails: EmailApi[] | null): string {
		const myEmail = _.find(emails, (email: EmailApi) => email.IsPrimary);
		return (myEmail) ? myEmail.Address : '';
	}

	private getPrimaryPhone(phones: PhoneApi[] | null): string {
		const myPhone = _.find(phones, (phone: PhoneApi) => phone.IsPrimary);
		return (myPhone) ? myPhone.Number : '';
	}
}
