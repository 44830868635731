export interface IProductView {
	channel: string;
    type: string;
    name: string;
}

export class ProductView implements IProductView {
	public channel: string;
    public type: string; 
    public name: string; 
}