const DefaultLoanOfficerPhoto = 'assets/img/nbkc-logo-letter-n.svg';

export class LoanOfficer {
	public firstName: string;
	public lastName: string;
	public loanOfficerMobilePhone: string;
	public loanOfficerOfficePhone: string;
	public loanOfficerEmail: string;
	public contentType: string;
	public nmlsNum: string;
	public photo: string;

	public get loanOfficerName(): string {
		return this.firstName + ' ' + this.lastName;
	}

	public get nmlsLink(): string {
		if (!this.nmlsNum || this.nmlsNum === '409631') {
			return 'http://nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/409631';
		}
		return 'http://nmlsconsumeraccess.org/EntityDetails.aspx/INDIVIDUAL/' + this.nmlsNum;
	}

	public get loanOfficerImageUrl(): string {
		return this.photo ? ('data:' + this.contentType + ';base64,' + this.photo) : DefaultLoanOfficerPhoto;
	}
}
