import { AdapterBase } from '@utilities/adapter-base';
import { InternalContact } from '@models/api/borrower-portal/internal-contact';
import { LoanOfficer } from '@models/loan-officer.model';
import { Constants } from '@utilities/constants';

export class PortfolioInternalContactToLoanOfficerAdapter extends AdapterBase<InternalContact, LoanOfficer> {
	public adapt(source: InternalContact): LoanOfficer {

            const lo = new LoanOfficer();
            lo.firstName = source.FirstName;
            lo.lastName = source.LastName;
            lo.loanOfficerOfficePhone = source.Phones?.find(x => x.IsPrimary)?.Number;
            lo.loanOfficerMobilePhone = source.Phones?.find(x => x.PhoneType === Constants.ContactPhoneTypes.Mobile)?.Number;
            lo.loanOfficerEmail = source.Email; 
            lo.nmlsNum = source.Nmls?.toString();
            lo.photo = source.Photo?.Content?.toString();

		    return lo;
	}
}
