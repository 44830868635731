import { DemographicAnswer } from '@models/form/demographics/demographic-helpers/demographic-answer.model';
import { Validate, Validators } from '@nbkc/validation';
import { DemographicSection } from './demographic-section.model';

export class DemographicsRace extends DemographicSection {
	@Validate(Validators.required())
	public americanIndianOrAlaskanNative: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public otherAmericanIndianOrAlaskanNativeFreeResponse: DemographicAnswer<string> = new DemographicAnswer('');
	@Validate(Validators.required())
	public asian: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public asianIndian: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public chinese: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public filipino: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public japanese: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public korean: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public vietnamese: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public otherAsian: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public otherAsianFreeResponse: DemographicAnswer<string> = new DemographicAnswer('');
	@Validate(Validators.required())
	public blackOrAfricanAmerican: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public nativeHawaiianOrOtherPacificIslander: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public nativeHawaiian: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public guamanianOrChamorro: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public samoan: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public otherPacificIslander: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public otherPacificIslanderFreeResponse: DemographicAnswer<string> = new DemographicAnswer('');
	@Validate(Validators.required())
	public white: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public noRace: DemographicAnswer<boolean> = new DemographicAnswer(false);

	constructor() {
		super();
	}
}
