import { LinkedAccountForm } from './linked-account.model';

export class LinkedAccountFormCollection {
	public id: string;
	public name: string;
	public type: string;
	public accounts: LinkedAccountForm[] = [];
	public sourceId: string;
	public source: string = 'plaid';
	public publicToken: string;
	public day1CertaintyToken: string;
	public linkedByUser: string;
	public status: string;
	public dateLinked: string;
	public dateUpdated: string;
}
