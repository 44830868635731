import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '@services/user.service';
import _ from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class LoGridGuard implements CanActivate {
	constructor(private userService: UserService, private router: Router) { }

	canActivate(): Observable<boolean | UrlTree> {
		if (this.userService.current) {
			if (!this.userService.isLoanOfficer) {
				return of(this.router.createUrlTree(['/']));
			}
			return of(true);
		}
		return of(false);
	}
}
