import { PortfolioView } from "./portfolio-view.model";
import { ProductView } from "./product-view.model";

export interface IAvailabilityView {
	portfolioViews: PortfolioView[];
    productViews: ProductView[];
}

export class AvailabilityView implements IAvailabilityView {
	public portfolioViews: PortfolioView[];
    public productViews: ProductView[];
}