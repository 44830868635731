import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Validatable } from '@nbkc/validation';

@Directive({
	selector: '[autoValidate]'
})
export class AutoValidateDirective implements OnInit, OnDestroy {
	@Input() autoValidate: Validatable;
	@Input() autoValidatePropertyKey: any;
	private parentDiv: HTMLElement;
	private wrapperDiv: HTMLElement;
	private setup: boolean = false;
	private wrapped: boolean = false;
	private errorTextDiv: any;
	private focused: boolean = false;
	private results: any;

	constructor(private el: ElementRef, private renderer: Renderer2, private ngModel: NgModel) {
	}

	public ngOnInit(): void {
		if (this.autoValidate && this.autoValidatePropertyKey) {
			this.setUpValidation();
		}
	}

	public ngOnDestroy(): void {
		setTimeout(() => {
			if (this.setup && this.wrapperDiv) {
				if (this.wrapped) {
					this.wrapperDiv.replaceWith(...Array.from(this.wrapperDiv.childNodes));
				} else {
					this.wrapperDiv.classList.remove('loan-app-required-radio');
					this.wrapperDiv.classList.remove('loan-app-required');
					this.wrapperDiv.classList.remove('loan-app-invalid');
					this.wrapperDiv.classList.remove('loan-app-valid');
				}
			}
		}, 500);
	}

	@HostListener('blur')
	public blur(): void {
		if (this.setup) {
			this.focused = false;
			this.renderer.addClass(this.wrapperDiv, 'loan-app-touched');
			this.runValidation();
		}
	}

	@HostListener('focus')
	public focus(): void {
		if (this.setup) {
			this.focused = true;
			this.makeValid(false);
		}
	}

	public makeValid(useAria: boolean = true): void {
		this.renderer.addClass(this.wrapperDiv, 'loan-app-valid');
		this.renderer.removeClass(this.wrapperDiv, 'loan-app-invalid');
		if (useAria) {
			this.renderer.setAttribute(this.el.nativeElement, 'aria-invalid', 'false');
		}
	}

	public makeInvalid(): void {
		this.renderer.removeClass(this.wrapperDiv, 'loan-app-valid');
		this.renderer.setAttribute(this.el.nativeElement, 'aria-invalid', 'true');
		if (this.errorTextDiv) {
			this.errorTextDiv.innerHTML = this.results.message;
		}
		if (!this.focused) {
			this.renderer.addClass(this.wrapperDiv, 'loan-app-invalid');
		}
	}

	private setUpValidation(): void {
		if (!this.setup) {
			if (this.el.nativeElement.tagName === 'INPUT' && (this.el.nativeElement as HTMLInputElement).type === 'radio') {
				this.parentDiv = this.el.nativeElement.parentNode.parentNode;
				this.parentDiv.classList.add('loan-app-required-radio');
				this.wrapperDiv = this.parentDiv;
			} else {
				this.wrapped = true;
				this.wrapperDiv = this.renderer.createElement('div');
				this.renderer.addClass(this.wrapperDiv, 'loan-app-required');
				this.parentDiv = this.el.nativeElement.parentNode;
				this.renderer.insertBefore(this.parentDiv, this.wrapperDiv, this.el.nativeElement);
				this.renderer.appendChild(this.wrapperDiv, this.el.nativeElement);
				this.errorTextDiv = this.renderer.createElement('div');
				this.renderer.addClass(this.errorTextDiv, 'field-error-text');
				this.renderer.appendChild(this.wrapperDiv, this.errorTextDiv);
			}
			if (this.autoValidate.validationEnabled) {
				this.renderer.setAttribute(this.el.nativeElement, 'aria-required', 'true');
			}
			this.runValidation();
			this.ngModel.valueChanges.subscribe(() => {
				if (!this.focused) {
					this.runValidation();
				}
			});
		}
		this.setup = true;
	}

	private runValidation(): void {
		// Firefox has a weird flicker when hovering over select if you use just the autovalidate property. Weird. We know.
		this.results = this.autoValidate.isValid(this.autoValidatePropertyKey);
		if (this.results.valid) {
			this.makeValid();
		} else {
			this.makeInvalid();
		}
	}
}
