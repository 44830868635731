import { Component, EventEmitter, Input, Output } from "@angular/core";

export abstract class ButtonToken {}

@Component({
  selector: 'lib-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  providers: [
    {provide: ButtonToken, useExisting: ButtonComponent}
  ]
})
export class ButtonComponent extends ButtonToken {
  @Input() isDisabled?: boolean = false;
  @Input() displayText: string;
  @Input() color?: string = 'primary';
  @Input() id: string;
  @Input() badgeContent: number;
  @Input() isBadgeHidden: boolean = true;
  @Output() clickEvent: EventEmitter<any> = new EventEmitter();

  constructor(){
    super();

  }

  onButtonClick(buttonId: string){
    this.clickEvent.emit(buttonId);
  }

}