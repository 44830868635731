import { QualificationResultApi } from '@models/api/qualification-result';
import { AdapterBase } from '@utilities/adapter-base';
import { QualificationResult } from '@models/form/qualification-result.model';

export class QualificationResultApiToQualificationResultAdapter extends AdapterBase<QualificationResultApi, QualificationResult> {
	public adapt(apiRule: QualificationResultApi): QualificationResult {
		return new QualificationResult({
			description: apiRule.RuleName,
			tags: apiRule.Tags,
			suggestion: apiRule.Suggestion
		});
	}
}
