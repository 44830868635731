import { Validatable, Validate, ValidateIf, Validators } from '@nbkc/validation';
import { EmailFormatRule } from '@utilities/rules/email-format-rule';

export class BorrowerForm extends Validatable {
	public index: number;
	@Validate(Validators.eighteenYearsOldDate('Please provide a date of birth (must be 18 years or older).'))
	public birthDate: string;
	public depAges: DependentAge[];
	public depCount: number;
	@ValidateIf("this.index > 0", new EmailFormatRule('Please enter a valid email address.')) public email: string;
	@Validate(Validators.required('Please enter a first name.')) public firstName: string;
	public militarySelfDeclaredService: boolean | null;
	public militaryActiveDuty: boolean | null;
	public militaryExpectedCompletionDate: string | null;
	public militaryVeteran: boolean | null;
	public militaryReserveNationalGuardReserveActivated: boolean | null;
	public militarySurvivingSpouse: boolean | null;
	@Validate(Validators.required('Please enter a last name.')) public lastName: string;
	@Validate(Validators.required('Please choose a marital status.')) public maritalStatus: string | null;
	public middleInitial: string;
	@Validate(Validators.maxLength(15, 'Suffix cannot be more than 15 characters.')) public suffix: string; 
	@Validate(Validators.phoneNumber('Please enter a valid phone number.')) public primaryPhone: string;
	@Validate(Validators.socialSecurity()) public maskedSsn: string;
	public ssn: string | null;
	@ValidateIf("this.ssn !== '***-**-****'",Validators.socialSecurity()) public verifyMaskedSsn: string | null;
	public verifySsn: string | null; 
	public isSSNVerified: boolean; 
	public haveEmployers: boolean;
	public emptyName: string = 'new borrower';

	public constructor(props: any = { }) {
		super();
		this.depAges = [];
		this.maritalStatus = '';
		this.depCount = 0;

		if (props) {
			this.emptyName = props.emptyName || 'new borrower';
		}
	}

	public firstNameDisplay(): string {
		let name = this.emptyName;

		if (this.firstName && this.firstName !== '') {
			name = this.firstName.trim();
			name = this.capitalize(name);
		}

		return name;
	}

	public nameDisplay(): string {
		if (this.firstName && this.lastName) {
			const firstName = this.capitalize(this.firstName.trim());
			const lastName = this.capitalize(this.lastName.trim());
			return firstName + ' ' + lastName;
		} else {
			return this.emptyName.trim();
		}
	}

	private capitalize(word: string): string {
		return word.charAt(0).toUpperCase() + word.slice(1);
	}
}

// Needed for issue with borrowers section handling string[]
export interface DependentAge {
	age: string;
}
