import { IdDescriptionPair } from '@lookups/lookups';

export const DependentChoices: IdDescriptionPair[] = [
	{
		id: 0,
		description: 'None'
	},
	{
		id: 1,
		description: '1'
	},
	{
		id: 2,
		description: '2'
	},
	{
		id: 3,
		description: '3'
	},
	{
		id: 4,
		description: '4'
	},
	{
		id: 5,
		description: '5'
	},
	{
		id: 6,
		description: '6 or More'
	},
];
