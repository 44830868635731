import { AdapterBase } from '@utilities/adapter-base';
import _ from 'lodash';
import { LoanApplicationApi } from '@models/api/loan-application';
import { EmailApi } from '@models/api/email';
import moment from 'moment';
import { ApplicationView } from '@models/application-view.model';
import { BorrowerRequestApi } from '@models/api/borrower-request';
import { FulfillmentDocumentApi } from '@models/api/fulfillment-documents';
import { DocumentRow } from '@models/form/document-row.model';
import { NumberHelper } from '@utilities/helpers';
import { BorrowerApi } from '@models/api/borrower';

export class LoanApiToApplicationViewAdapter extends AdapterBase<LoanApplicationApi, ApplicationView> {
	public adapt(app: LoanApplicationApi): ApplicationView {
		const preview = new ApplicationView();
		app.Borrowers = (app.Borrowers.length > 0) ? app.Borrowers : [new BorrowerApi({ BorrowerIndex: 0 })];
		preview.LoanNumber = app.Identifiers.LoanNumber;
		preview.ApplicationStatus = app.ApplicationStatus;
		preview.CreatedDt = moment(app.CreatedDate).toDate();
		preview.SubmittedDt = (app.SubmittedDate) ? moment(app.SubmittedDate).toDate() : null;
		preview.ModifiedDt = moment(app.LastModifiedDate).toDate();
		preview.SubjectPropertyAddress = app.SubjectProperty.Address1;
		preview.SubjectPropertyCity = app.SubjectProperty.City;
		preview.SubjectPropertyState = app.SubjectProperty.StateAbbreviation;
		preview.SubjectPropertyZip = app.SubjectProperty.PostalCode;
		preview.SubjectPropertyCounty = app.SubjectProperty.County;
		preview.SubjectPropertyCountryCode = app.SubjectProperty.CountryCode;
		preview.BorrowerEmail = this.getPrimaryEmail(app.Borrowers[0].Emails);
		preview.BorrowerName = app.Borrowers[0].FirstName && app.Borrowers[0].LastName 
			? app.Borrowers[0].FirstName + ' ' + app.Borrowers[0].LastName
			: null;
		if (app.LoanOfficer) {
			preview.LoanOfficerName = app.LoanOfficer.DisplayName;
			preview.LoanOfficerId = app.LoanOfficer.UserId;
		}
		if (app.RequestedLoanDetails) {
			preview.ApplicationPurpose = app.RequestedLoanDetails.LoanPurpose;
			preview.RequestedAmount = NumberHelper.numberToString(app.RequestedLoanDetails.LoanAmount);
		}
		preview.ApplicationId = app.Identifiers.ApplicationId;
		preview.PortfolioId = app.Identifiers.PlatformRefId; 
		preview.Progress = app.ApplicationProgress;
		const documents: DocumentRow[] = [];
		_.forEach(app.BorrowerRequests, (request: BorrowerRequestApi) => {
			_.forEach(request.FulfillmentDocuments, (doc: FulfillmentDocumentApi) => {
				const document = new DocumentRow();
				document.DocumentType = request.Name;
				document.CreatedDt = new Date(doc.CreatedDate);
				document.Filename = doc.Filename;
				document.DocumentStatus = doc.DocumentStatus;
				document.PortfolioId = app.Identifiers.PlatformRefId;
				document.RequestId = request.BorrowerRequestId;
				document.DocumentId = doc.DocumentId;
				document.ApplicationId = app.Identifiers.ApplicationId;
				document.downloaded = false;
				document.downloading = false;
				documents.push(document);
			});
		});
		preview.Documents = documents;
		preview.DocumentsReceived = documents.length;

		// It takes a few hundred milliseconds to adapt to LoanApplicationForm. Keep API reference and adapt when needed.
		preview.apiLoan = app;

		return preview;
	}

	private getPrimaryEmail(emails: EmailApi[] | null): string {
		const myEmail = _.find(emails, (email: EmailApi) => email.IsPrimary);
		return (myEmail) ? myEmail.Address : '';
	}
}
