import { InternalContact } from "./api/borrower-portal/internal-contact";

export interface IPortfolioView {
	portfolioId: string;
	portfolioType: string; 
	portfolioStage: string;
	createDate: Date
	submittedDate: Date | null; 
	sortDate: Date;
	entryPoint: string; 
	description: string;
	loanNumber: string;
}

export class PortfolioView implements IPortfolioView {
	public portfolioId: string;
	public portfolioType: string; 
	public portfolioStage: string;
	public createDate: Date; 
	public submittedDate: Date;
	public sortDate: Date;
	public entryPoint: string;
	public description: string;
	public loanNumber: string;
}