import { Validatable, Validate, ValidateIf, Validators } from '@nbkc/validation';
import { AddressForm } from './address.model';

export class ResidenceForm extends Validatable {
	public borrowerIndexes: number[];
	@Validate(Validators.required()) public address: AddressForm;
	@ValidateIf('this.isCurrent === true && !this.mailingAddressSameAsResidence', Validators.required())
	public mailingAddress: AddressForm;
	@Validate(Validators.required()) public duration: number;
	public isCurrent: boolean;
	public isOwned: boolean;
	public mailingAddressSameAsResidence: boolean = true;
	public rentAmount: string;
	public yearsDuration: number;
	public monthsDuration: number;
	public durationTouched: boolean;
	public durationFocused: boolean;

	constructor() {
		super();
		this.address = new AddressForm();
		this.address.isNew = true;
		this.mailingAddress = new AddressForm({ requireCounty: false });
		this.mailingAddress.isNew = true;
		this.durationTouched = false;
		this.durationFocused = false;
	}
}
