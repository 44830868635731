export class EsignPackageStatus {
	public static unknown: string = 'Unknown';
	public static live: string = 'Live';
	public static consented: string = 'Consented';
	public static inProgress: string = 'InProgress';
	public static reviewed: string = 'Reviewed';
    public static printed: string = 'Printed';
	public static signed: string = 'Signed';
	public static consentDeclined: string = 'ConsentDeclined';

}