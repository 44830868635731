/* eslint-disable max-len */

import _ from 'lodash';
import moment from 'moment';
import { AdapterBase } from '@utilities/adapter-base';
import { DateHelper, NumberHelper } from '@utilities/helpers';
import { PortfolioTypes, SectionNames } from '@constants';
import { LoanApplicationApi } from '@models/api/loan-application';
import { CurrentApplicationForm } from '@models/form/loan-application-form.model';
import { AddressForm } from '@models/form/address.model';
import { LiabilityApi } from '@models/api/liability';
import { ResidenceForm } from '@models/form/residence.model';
import { FormProgress } from '@models/form/progress/form.progress.model';
import { DeclarationsProgress } from '@models/form/progress/declarations.progress.model';
import { DeclarationsApi } from '@models/api/declarations';
import { BorrowerForm } from '@models/form/borrower.model';
import { LoanForm } from '@models/form/loan.model';
import { BorrowerApi } from '@models/api/borrower';
import { BorrowerConsent } from '@models/api/borrower-consent';
import { ResidenceApiToResidenceFormAdapter } from './residence-api-to-residence-form.adapter';
import { LoanDetailsApiToLoanFormAdapter } from './loan-details-api-to-loan-form.adapter';
import { BorrowerApiToBorrowerFormAdapter } from './borrower-api-to-borrower-form.adapter';
import { AssetApiToAccountFormAdapter } from './asset-api-to-account-form.adapter';
import { ReoPropertyApiToRealEstateFormAdapter } from './reo-property-api-to-real-estate-form.adapter';
import { EmployerApiToEmploymentFormAdapter } from './employer-api-to-employment-form.adapter';
import { DeclarationsApiToBorrowerDeclarations2009FormAdapter } from './declarations-api-to-borrower-declarations-form2009.adapter';
import { IncomeSourceApiToOtherIncomeFormAdapter } from './income-source-api-to-other-income-form.adapter';
import { ContactApiToContactFormAdapter } from './contact-api-to-contact-form.adapter';
import { BorrowerRequestApiToRequiredDocumentAdapter } from './borrower-request-api-to-required-document.adapter';
import { QualificationResultApiToQualificationResultAdapter } from './qualification-result-api-to-qualification-result.adapter';
import { LinkedAccountApiToLinkedAccountFormCollectionAdapter } from './linked-account-api-to-linked-account-form-collection.adapter';
import { DemographicApiCollectionToBorrowerDemographicFormAdapter } from './demographic-api-collection-to-borrower-demographic-form.adapter';
import { SupplementApplicationResultsApi } from '@models/api/supplement-application-results';
import { DeclarationsApiToBorrowerDeclarations2020FormAdapter } from './declarations-api-to-borrower-declarations-form2020.adapter';
import { IBorrowerDeclarationsForm } from '@models/form/declarations/borrower-declarations';
import { ReferralSource } from '@models/form/referral-source-model';
import { ReferralApi } from '@models/api/referral';

export class SupplementApplicationResultsApiToCurrentApplicationFormAdapter extends AdapterBase<SupplementApplicationResultsApi, CurrentApplicationForm> {
	private _apiPackage: SupplementApplicationResultsApi;
	private _apiData: LoanApplicationApi;
	private _loanApplicationForm: CurrentApplicationForm;
	private _residenceApiToResidenceFormAdapter: ResidenceApiToResidenceFormAdapter = new ResidenceApiToResidenceFormAdapter();
	private _loanDetailsApiToLoanFormAdapter: LoanDetailsApiToLoanFormAdapter = new LoanDetailsApiToLoanFormAdapter();
	private _borrowerApiToBorrowerFormAdapter: BorrowerApiToBorrowerFormAdapter = new BorrowerApiToBorrowerFormAdapter();
	private _qualificationResultApiToQualificationResultAdapter: QualificationResultApiToQualificationResultAdapter = new QualificationResultApiToQualificationResultAdapter();
	private _assetApiToAccountFormAdapter: AssetApiToAccountFormAdapter = new AssetApiToAccountFormAdapter();
	private _reoPropertyApiToRealEstateFormAdapter: ReoPropertyApiToRealEstateFormAdapter = new ReoPropertyApiToRealEstateFormAdapter();
	private _employmentApiToEmploymentFormAdapter: EmployerApiToEmploymentFormAdapter = new EmployerApiToEmploymentFormAdapter();
	private _declarationsApiToDeclarations2009Form: DeclarationsApiToBorrowerDeclarations2009FormAdapter = new DeclarationsApiToBorrowerDeclarations2009FormAdapter();
	private _declarationsApiToDeclarations2020Form: DeclarationsApiToBorrowerDeclarations2020FormAdapter = new DeclarationsApiToBorrowerDeclarations2020FormAdapter();
	private _incomeSourceApiToOtherIncomeFormAdapter: IncomeSourceApiToOtherIncomeFormAdapter = new IncomeSourceApiToOtherIncomeFormAdapter();
	private _contactApiToContactFormAdapter: ContactApiToContactFormAdapter = new ContactApiToContactFormAdapter();
	private _demographicApiCollectionToBorrowerDemographicFormAdapter: DemographicApiCollectionToBorrowerDemographicFormAdapter = new DemographicApiCollectionToBorrowerDemographicFormAdapter();
	private _borrowerRequestApiToRequiredDocumentAdapter: BorrowerRequestApiToRequiredDocumentAdapter = new BorrowerRequestApiToRequiredDocumentAdapter();
	private _linkedAccountApiToLinkedAccountFormCollectionAdapter: LinkedAccountApiToLinkedAccountFormCollectionAdapter = new LinkedAccountApiToLinkedAccountFormCollectionAdapter();

	public adapt(apiData: SupplementApplicationResultsApi): CurrentApplicationForm {
		this._apiPackage = _.cloneDeep(apiData);
		this._apiData = this._apiPackage.ModifiedApplication;
		this._loanApplicationForm = this._adaptApplication();
		this._loanApplicationForm.originalApplicationApi = _.cloneDeep(this._apiData);
		this._loanApplicationForm.progress = this._apiData.ApplicationProgress
			? new FormProgress(JSON.parse(this._apiData.ApplicationProgress))
			: new FormProgress();
		this._loanApplicationForm.loan = this._adaptLoan();
		this._loanApplicationForm.realEstate = this._reoPropertyApiToRealEstateFormAdapter.adaptCollection(
			this._apiData.ReoProperties
		);
		this._loanApplicationForm.loanOfficerId = this._apiData.LoanOfficer?.UserId;
		this._loanApplicationForm.internalContact = apiData.InternalContact; 
		this._loanApplicationForm.accounts = this._assetApiToAccountFormAdapter.adaptCollection(this._apiData.Assets);
		this._loanApplicationForm.linkedAccounts = this._linkedAccountApiToLinkedAccountFormCollectionAdapter.adaptCollection(this._apiData.LinkedAccounts);
		this._loanApplicationForm.borrowers = this._adaptBorrowers();
		this._loanApplicationForm.businessRules = this._qualificationResultApiToQualificationResultAdapter.adaptCollectionWith(
			_.filter(this._apiPackage.QualificationResults, (x) => _.includes(x.Tags, 'businessRule')),
			(item, result) => {
				result.passed = item.PassesCondition;
				return result;
			},
			{ compact: true }
		);
		this._loanApplicationForm.failedRules = this._qualificationResultApiToQualificationResultAdapter.adaptCollectionWith(
			_.filter(this._apiPackage.QualificationResults, (x) => x.DisqualifiedForMilestone),
			(item, result) => {
				result.passed = false;
				return result;
			},
			{ compact: true }
		);
		this._loanApplicationForm.requiredDocuments = this._borrowerRequestApiToRequiredDocumentAdapter.adaptCollection(
			this._apiData.BorrowerRequests
		);
		return this._loanApplicationForm;
	}

	private _adaptApplication(): CurrentApplicationForm {
		const loanApplicationForm = new CurrentApplicationForm();
		loanApplicationForm.urlaVersion = this._apiData.UrlaVersion;
		loanApplicationForm.id = this._apiData.Identifiers.ApplicationId;
		loanApplicationForm.urlToken = this._apiData.Identifiers.UrlToken;
		loanApplicationForm.platformRefId = this._apiData.Identifiers.PlatformRefId;
		loanApplicationForm.loanGuid = this._apiData.Identifiers.LoanGuid;
		loanApplicationForm.applicationPurposeId = this._apiData.RequestedLoanDetails.LoanPurpose; // probably need to be changed to display names
		loanApplicationForm.applicationStatusId = this._apiData.ApplicationStatus; // probably need to be changed to display names
		loanApplicationForm.modified = DateHelper.dateToString(this._apiData.LastModifiedDate);
		loanApplicationForm.created = DateHelper.dateToString(this._apiData.CreatedDate);
		loanApplicationForm.loanOfficerId = this._apiData.LoanOfficer ? this._apiData.LoanOfficer.UserId : '';
		loanApplicationForm.isQualifiedForSubmission = this._apiPackage.IsQualifiedForSubmission;
		loanApplicationForm.hasVaEligibleBorrower = this._apiData.HasVaEligibleBorrower;
		loanApplicationForm.eConsent = this.isEConsent(this._apiData.BorrowerConsents);
		loanApplicationForm.referral = this._adaptReferral(this._apiData.Referral);
		
		return loanApplicationForm;
	}

	private isEConsent(borrowerConsentArray: BorrowerConsent[]): boolean {
		const eConsent = _.find(borrowerConsentArray, (borrowerConsent: BorrowerConsent) => {
			return borrowerConsent.ConsentType === 'ElectronicSignaturesAndRecords';
		});
		return !!(
			eConsent &&
			eConsent.ConsentStatus === 'Consented' &&
			moment().isBefore(eConsent.Expiration) &&
			moment().isAfter(eConsent.ConsentDate)
		);
	}

	private _adaptLoan(): LoanForm {
		return this._loanDetailsApiToLoanFormAdapter.adaptWith(this._apiData.RequestedLoanDetails, (item, result) => {
			result.estimatedValueOrPurchasePrice = NumberHelper.numberToString(
				this._apiData.SubjectProperty.EstimatedValue
			);
			result.occupancyType = this._apiData.SubjectProperty.PropertyType || null;
			result.state = this._apiData?.SubjectProperty?.StateAbbreviation || '';
			
			result.outstandingMortgage = this._apiData?.SubjectProperty?.FirstLienBalance?.toString() || '';
			result.propertyAddress = new AddressForm({
				address1:
					this._apiData.SubjectProperty && this._apiData.SubjectProperty.Address1
						? this._apiData.SubjectProperty.Address1
						: '',
				city:
					this._apiData.SubjectProperty && this._apiData.SubjectProperty.City
						? this._apiData.SubjectProperty.City
						: '',
				state:
					this._apiData.SubjectProperty && this._apiData.SubjectProperty.StateAbbreviation
						? this._apiData.SubjectProperty.StateAbbreviation
						: '',
				unitType:
					this._apiData.SubjectProperty && this._apiData.SubjectProperty.UnitType
						? this._apiData.SubjectProperty.UnitType
						: '',
				unitNumber:
					this._apiData.SubjectProperty && this._apiData.SubjectProperty.UnitNumber
						? this._apiData.SubjectProperty.UnitNumber
						: '',
				zip:
					this._apiData.SubjectProperty && this._apiData.SubjectProperty.PostalCode
						? this._apiData.SubjectProperty.PostalCode
						: '',
				county:
					this._apiData.SubjectProperty && this._apiData.SubjectProperty.County
						? this._apiData.SubjectProperty.County
						: '',
				countryCode:
					this._apiData.SubjectProperty && this._apiData.SubjectProperty.CountryCode
						? this._apiData.SubjectProperty.CountryCode
						: ''
			});
		});
	}

	private _adaptBorrowers(): BorrowerForm[] {
		this._apiData.Borrowers =
			this._apiData.Borrowers.length > 0 ? this._apiData.Borrowers : [new BorrowerApi({ BorrowerIndex: 0 })];
		return this._borrowerApiToBorrowerFormAdapter.adaptCollectionWith(this._apiData.Borrowers, (borrower) => {
			this._loanApplicationForm.employment = this._loanApplicationForm.employment.concat(
				this._employmentApiToEmploymentFormAdapter.adaptCollection(borrower.Employers, {
					borrowerIndex: borrower.BorrowerIndex,
					isRefinance: (this._loanApplicationForm.loan.purpose === PortfolioTypes.refinance || this._loanApplicationForm.loan.purpose === PortfolioTypes.cashOutRefinance)
				})
			);
			this._loanApplicationForm.income = this._loanApplicationForm.income.concat(
				this._incomeSourceApiToOtherIncomeFormAdapter.adaptCollection(borrower.NonEmploymentIncome, {
					borrowerIndex: borrower.BorrowerIndex
				})
			);
			this._loanApplicationForm.residences = _.transform(
				this._residenceApiToResidenceFormAdapter.adaptCollection(borrower.Residences, {
					borrowerIndex: borrower.BorrowerIndex
				}),
				(result, value) => {
					const existingResidence = _.find(result, (item) => this._isSameResidence(item, value));
					!existingResidence
						? result.push(value)
						: existingResidence.borrowerIndexes.push(value.borrowerIndexes[0]);
					return result;
				},
				this._loanApplicationForm &&
					this._loanApplicationForm.residences &&
					this._loanApplicationForm.residences.length
					? this._loanApplicationForm.residences
					: []
			);

			let borrowerDeclarations: IBorrowerDeclarationsForm;
			if (this._apiData.UrlaVersion === '2020') {
				borrowerDeclarations = this._declarationsApiToDeclarations2020Form.adaptWith(
					borrower.Declarations || new DeclarationsApi(),
					this.monthlyLiabilitiesToDeclarationAmountsAdapter(borrower),
					{ borrowerIndex: borrower.BorrowerIndex }
				);
			} else {
				borrowerDeclarations = this._declarationsApiToDeclarations2009Form.adaptWith(
					borrower.Declarations || new DeclarationsApi(),
					this.monthlyLiabilitiesToDeclarationAmountsAdapter(borrower),
					{ borrowerIndex: borrower.BorrowerIndex }
				);
			}

			this._loanApplicationForm.declarations.borrowerDeclarations = this._loanApplicationForm.declarations.borrowerDeclarations.concat(
				borrowerDeclarations
			);
			const declarationsProgress = new DeclarationsProgress(
				this._loanApplicationForm.progress.sections[SectionNames.declarations]
			);

			if (_.find(this._apiData.ApplicationContacts, (contact) => contact.ContactType === 'BuyersAgent')) {
				this._loanApplicationForm.loan.realEstateAgent = this._contactApiToContactFormAdapter.adapt(
					_.find(this._apiData.ApplicationContacts, (contact) => contact.ContactType === 'BuyersAgent'),
					{
						contactType: 'BuyersAgent',
						declarationsProgress
					}
				);
			}
			if (_.find(this._apiData.ApplicationContacts, (contact) => contact.ContactType === 'HazardInsurance')) {
				this._loanApplicationForm.declarations.insuranceCompany = this._contactApiToContactFormAdapter.adapt(
					_.find(this._apiData.ApplicationContacts, (contact) => contact.ContactType === 'HazardInsurance'),
					{
						contactType: 'HazardInsurance',
						declarationsProgress
					}
				);
			}
			const borrowerDemographics = this._demographicApiCollectionToBorrowerDemographicFormAdapter.adapt(
				borrower.Demographics,
				{ borrowerIndex: borrower.BorrowerIndex }
			);
			this._loanApplicationForm.demographics.borrowerDemographics = this._loanApplicationForm.demographics.borrowerDemographics.concat(
				borrowerDemographics
			);
		});
	}

	private monthlyLiabilitiesToDeclarationAmountsAdapter(borrower: BorrowerApi): (item: DeclarationsApi, result: IBorrowerDeclarationsForm) => void {
		return (item: DeclarationsApi, result: IBorrowerDeclarationsForm) => {
			result.alimonyPayments = _.get(
				_.find(borrower.Liabilities, (liability: LiabilityApi) => liability.Description === 'Alimony'),
				'MonthlyPaymentAmount',
				null
			);
			result.childSupportPayments = _.get(
				_.find(
					borrower.Liabilities,
					(liability: LiabilityApi) => liability.Description === 'ChildSupport'
				),
				'MonthlyPaymentAmount',
				null
			);
		}
	}

	private _isSameResidence(a: ResidenceForm, b: ResidenceForm): boolean {
		if (a && b) {
			return _.isEqual(this.getResidenceLikeComparisonObject(a), this.getResidenceLikeComparisonObject(b));
		} else {
			return false;
		}
	}

	private getResidenceLikeComparisonObject(res: ResidenceForm): any {
		return [
			res.address.address1,
			res.address.city,
			res.address.state,
			res.address.zip,
			res.address.unitNumber,
			res.address.unitType,
			res.address.county,
			res.address.countryCode,
			res.isOwned,
			res.duration,
			res.yearsDuration,
			res.monthsDuration
		];
	}

	private _adaptReferral(referral: ReferralApi) : ReferralSource{
		if (!referral){
			return null;
		}

		const referralSource : ReferralSource = new ReferralSource();
		referralSource.referralSource = referral.ReferralSource;
		referralSource.referralSourceDetails = referral.ReferralSourceDetails;

		return referralSource;
	}
}