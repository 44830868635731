<mat-list class="list">
    <mat-list-item *ngFor="let package of incompletePackages" class="list-item">
        <mat-icon matListItemIcon class="list-item-icon-warn" *ngIf="package.Status === 'InProgress'" fontSet="fal" fontIcon="fa-exclamation-circle"></mat-icon>
        <h4 matListItemTitle>{{package.PackageType}}</h4>
        <p matListItemLine class="widget-list-subtitle">Ready to sign: {{getFormatedDate(package.CreateDate)}}
        </p>
        <lib-button matListItemMeta card-footer-button class="material-button-no-padding" [id]="'lib-right-action'"
            [displayText]="'View Now'" (click)="goToLink(package.PortalUrl, package.PackageId, package.SignerName, package.PackageType)">
        </lib-button>
    </mat-list-item>
    <mat-list-item *ngIf="inCompletedPackagesCount === 0" class="list-item">
        <mat-icon matListItemIcon class="list-item-icon" fontSet="fal" fontIcon="fa-check-circle"></mat-icon>
        <h4 matListItemTitle>No documents ready to sign yet.</h4>
    </mat-list-item>
    <ng-container *ngIf="showCompleted">
        <mat-list-item *ngFor="let completedPackage of completedPackages" class="list-item">
            <mat-icon matListItemIcon class="list-item-icon"fontSet="fal" fontIcon="fa-check-circle"></mat-icon>
            <h4 matListItemTitle>{{completedPackage.PackageType}}</h4>
            <lib-button matListItemMeta card-footer-button class="material-button-no-padding" [id]="'lib-right-action'"
                [displayText]="'View Now'" (click)="goToLink(completedPackage.PortalUrl, completedPackage.PackageId, completedPackage.SignerName, completedPackage.PackageType)">
            </lib-button>
        </mat-list-item>
    </ng-container>
    <ng-container *ngIf="completedPackagesCount > 0 && !showCompleted">
        <div class="see-more-button">
            <button mat-button color="primary" (click)="showCompleted = !showCompleted">See Completed
                ({{completedPackagesCount}})</button>
        </div>
    </ng-container>
    <ng-container *ngIf="completedPackagesCount > 0 && showCompleted">
        <div class="see-more-button">
            <button mat-button color="primary" (click)="showCompleted = !showCompleted">Hide Completed</button>
        </div>
    </ng-container>
</mat-list>