import { Validatable, Validators, Validate } from '@nbkc/validation';
import { ContactForm } from './contact-form.model';
import { IBorrowerDeclarationsForm } from './declarations/borrower-declarations';

export class Declarations extends Validatable {
	@Validate(Validators.required())
	public realEstateAgent: ContactForm = new ContactForm();
	public insuranceCompany: ContactForm = new ContactForm();

	@Validate(Validators.required())
	public borrowerDeclarations: IBorrowerDeclarationsForm[] = [];

	constructor() {
		super();
	}
}
