import { AddressApi } from './address';
import { PhoneApi } from './phone';

export class EmployerApi {
	public BusinessName: string;
	public Address: AddressApi;
	public Phone: PhoneApi | null;
	public Title: string | null;
	public SelfEmployed: boolean;
	public OwnershipPercentage: number | null;
	public EmployerIsFamily: boolean;
	public EmployerIsPartyToTransaction: boolean;
	public CurrentEmployment: boolean;
	public StartDate: string | null;
	public EndDate: string | null;
	public TimeInLineOfWorkYears: number | null;
	public AnnualBaseSalary: number | null;
	public AnnualCommission: number | null;
	public AnnualBonus: number | null;
	public AnnualOvertime: number | null;
}
