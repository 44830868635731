import {
	TrackerEvent,
	ApplicationInsightsProviderMapping,
	ConsoleTrackingProvider,
	ApplicationInsightsTrackingProvider,
	ApplicationInsightsEvent
} from '@nbkc/tracker';
import { ApplicationTrackerContext } from '../application-tracker-context';
import { TrackingProvider } from '@nbkc/tracker/dist/providers/tracking-provider';

export class PageVisibleEvent extends TrackerEvent<ApplicationTrackerContext>
	implements ApplicationInsightsProviderMapping {
	public name: string = 'page-visible';
	public providers: (typeof TrackingProvider)[] = [ConsoleTrackingProvider, ApplicationInsightsTrackingProvider];

	public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = this.name;
		return event;
	}
}
