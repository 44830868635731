import {
	TrackerEvent,
	ApplicationInsightsProviderMapping,
	ConsoleTrackingProvider,
	ApplicationInsightsTrackingProvider,
	ApplicationInsightsEvent
} from '@nbkc/tracker';
import { ApplicationTrackerContext } from '../application-tracker-context';
import { TrackingProvider } from '@nbkc/tracker/dist/providers/tracking-provider';

export class LoanOfficerProfileIncompleteEvent extends TrackerEvent<ApplicationTrackerContext>
	implements ApplicationInsightsProviderMapping {
	public name: string = 'loan-officer-profile-incomplete';
	public providers: typeof TrackingProvider[] = [ConsoleTrackingProvider, ApplicationInsightsTrackingProvider];

	public constructor(public data: ConfigurationLoadedProps) {
		super();
	}

	public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = this.name;
		event.data = {
			profile: this.data.response
		};
		return event;
	}
}

interface ConfigurationLoadedProps {
	response: any;
}
