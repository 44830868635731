import { ValidationRule } from "@nbkc/validation/dist/rules/validation-rule";

const defaultMessage: string = 'Please enter a valid email.';
const ruleName: string = 'validEmail';

export class EmailFormatRule extends ValidationRule {
    constructor(errorMessage?: string) {
        super(errorMessage || defaultMessage, ruleName);
    }

    public conditionForInvalid(value?: string): boolean {

        let regex = new RegExp('^(([^<>()\\[\\]\\.,;:\\s@\\"\\"]+(\.[^<>()\\[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');

		let isInvalid = !regex.test(value);

        return isInvalid;
    }
}