import {
	TrackerError,
	ApplicationInsightsProviderMapping,
	GoogleAnalyticsProviderMapping,
	ApplicationInsightsError,
	GoogleAnalyticsError
} from '@nbkc/tracker';
import { ApplicationTrackerContext } from '../application-tracker-context';
import { HttpErrorResponse } from '@angular/common/http';

export class HttpError extends TrackerError<ApplicationTrackerContext>
	implements ApplicationInsightsProviderMapping, GoogleAnalyticsProviderMapping {
	public name: string = 'http-request-failure';

	public constructor(public data: HttpErrorProps) {
		super(new Error('http request failed'));
	}

	public ai(): ApplicationInsightsError {
		const aiEvent = new ApplicationInsightsError();
		aiEvent.name = this.name;
		aiEvent.extras = this.data.error;
		aiEvent.error = this.error;
		return aiEvent;
	}

	public ga(): GoogleAnalyticsError {
		const event = new GoogleAnalyticsError();
		event.label = JSON.stringify({ extras: this.data.error, error: this.error });
		return event;
	}
}

interface HttpErrorProps {
	error: HttpErrorResponse;
}
