import { AdapterBase } from '@utilities/adapter-base';
import { ReoPropertyApi } from '@models/api/reo-property';
import { RealEstateForm } from '@models/form/real-estate.model';
import { AddressApiToAddressFormAdapter } from './address-api-to-address-form.adapter';
import { NumberHelper } from '@utilities/helpers';

export class ReoPropertyApiToRealEstateFormAdapter extends AdapterBase<ReoPropertyApi, RealEstateForm> {
	private _addressApiToAddressFormAdapter: AddressApiToAddressFormAdapter = new AddressApiToAddressFormAdapter();

	public adapt(source: ReoPropertyApi): RealEstateForm {
		const dest = new RealEstateForm();
		dest.address = this._addressApiToAddressFormAdapter.adapt(source.Address);
		dest.address.requireCounty = false;
		dest.propertyUsageType = source.PropertyUsageType || null;
		return dest;
	}
}
