import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import _ from 'lodash';
import { UserService } from '@services/user.service';

@Injectable({
	providedIn: 'root'
})
export class MyAppsGuard implements CanActivate {
	constructor(private userService: UserService, private router: Router) {
	}

	canActivate(): Observable<boolean | UrlTree> {
		if (this.userService.current) {
			if (this.userService.isLoanOfficer) {
				return of(this.router.createUrlTree(['/lo']));
			}
			return of(true);
		}
		return of(false);
	}
}
