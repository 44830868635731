import { Validatable, Validate, Validators } from '@nbkc/validation';
import _ from 'lodash';

export class BorrowerDeclaredBankruptciesForm extends Validatable {
	@Validate(Validators.required())
	public chapter7: boolean;
	@Validate(Validators.required())
	public chapter11: boolean;
	@Validate(Validators.required())
	public chapter12: boolean;
	@Validate(Validators.required())
	public chapter13: boolean;

	constructor() {
		super();
	}

	public anyChecked(): boolean {
		return _.some(this.internalValues, (prop: boolean) => {
			return prop === true;
		});
	}

	public clearAll(): void {
		this.chapter7 = false;
		this.chapter11 = false;
		this.chapter12 = false;
		this.chapter13 = false;
	}
}
