import { SectionProgress } from './section.progress.model';

export class DeclarationsProgress extends SectionProgress {
	public answeredContact: string[];

	constructor(props: any = { }) {
		super(props);
		this.answeredContact = props.answeredContact || [];
	}
}
