<div class="loan-app-modal-main-content">
	<div class="loan-app-modal-header">Are you sure you want to start a new application?</div>
	<div class="loan-app-modal-body">
		<div *ngIf="!mostRecentApplication.SubmittedDt">{{applicationIsCreatedText()}}. <a class="modal-body-action"
				(click)="goToApplication()" id="continueAppButtonModal">Continue Application</a></div>
		<div *ngIf="mostRecentApplication.SubmittedDt">{{applicationIsSubmittedText()}}. <a class="modal-body-action"
				(click)="goToDocumentUploads()" id="continueUploadAppButtonModal">Upload Documents</a></div>
	</div>
	<div class="loan-app-modal-footer loan-app-modal-actions">
		<button class="loan-app-action-button loan-app-button-text" keyboardFocus type="button" (click)="close()"
			id="cancelCreatingNewAppButton">Cancel</button>
		<button mat-raised-button color="primary" class="loan-app-action-button blue-material-button" type="button" (click)="continue()"
			id="startANewAppModalButton">Start a new application</button>
	</div>
</div>
