import {
	TrackerEvent,
	ApplicationInsightsEvent,
	GoogleAnalyticsEvent,
	GoogleTagManagerEvent,
} from '@nbkc/tracker';
import { ApplicationTrackerContext } from '../application-tracker-context';

export class LoanApplicationSubmittedEvent extends TrackerEvent<ApplicationTrackerContext>{
	public name: string = 'loanapp-submit-';
	public applicationPurpose : string = 'unknown';

	constructor(purpose: string) {
		super();
		this.applicationPurpose = purpose.toLowerCase();
	}

	public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = this.name + this.applicationPurpose;
		return event;
	}

	public ga(): GoogleAnalyticsEvent {
		return new GoogleAnalyticsEvent({
			category: 'Application',
			action: 'Click',
			label: 'Submit'
		});
	}

	public gtm(): GoogleTagManagerEvent {
		const event = new GoogleTagManagerEvent();
		event.event = this.name + this.applicationPurpose;
		return event;
	}
}
