import _ from 'lodash';

export const convertCollectionToMap = <tKey, tValue>(
	collection: object,
	key: string,
	value: string
): Map<tKey, tValue> => {
	const newMap = new Map<tKey, tValue>();
	_.forEach(collection, (entry) => {
		newMap.set(entry[key], entry[value]);
	});
	return newMap;
};
