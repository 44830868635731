import _ from 'lodash';
import { Injectable } from '@angular/core';
@Injectable({
	providedIn: 'root'
})
export class CacheService {

	public get(key: string): any {
		return this.getObjectIfJsonReturnValueIfNot(localStorage.getItem(key));
	}

	public clear(key: string): void {
		localStorage.removeItem(key);
	}

	public set(keyOrValue: any, value?: any): void {
		if (_.isObjectLike(keyOrValue)) {
			_.forEach(keyOrValue, (objValue, key) => {
				this.set(key, objValue);
			});
		} else {
			this.store(keyOrValue, value);
		}
	}

	private store(key: string, value: any): void {
		if (key.includes('_')) {
			throw new Error('The key for the stored value cannot contain an underscore and must be kebab cased.');
		}
		if (_.isObjectLike(value)) {
			value = this.updateExistingData(key, value);
			if (Object.keys(value).length > 0) {
				value = JSON.stringify(value);
			} else {
				value = null;
			}
		}

		if (value && !_.isEmpty(value) && !_.isNull(value) && value !== '') {
			localStorage.setItem(key, value);
		}
	}

	private getObjectIfJsonReturnValueIfNot(jsonOrValue: any): any {
		try {
			return JSON.parse(jsonOrValue);
		} catch (e) {
			return jsonOrValue;
		}
	}

	private updateExistingData(key: string, value: any): any {
		const existingData = this.get(key);

		if (existingData) {
			_.mergeWith(existingData, value, (dest, source) => {
				if (source && !_.isEmpty(source) && !_.isNull(source) && source !== '') {
					return source;
				}
				return dest;
			});
		}

		return existingData || value;
	}
}
