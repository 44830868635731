<div *ngIf="!isStatusMessageVisible()" class="nbkc-card-section">
	<div class="payment-form-container">
		<div class="payment-form-body">
			<div class="card-images-container loan-app-form-control">
				<img src="/assets/img/visa.svg" alt="Visa" class="cc-image" />
				<img src="/assets/img/mastercard.svg" alt="MasterCard" class="cc-image" />
				<img src="/assets/img/americanexpress.svg" alt="American Express" class="cc-image" />
				<img src="/assets/img/discover.svg" alt="Discover" class="cc-image no-right-margin" />
			</div>
			<h1 class="line-item-row nbkc-section-title">Payment Summary</h1>
			<ng-container *ngIf="!!portfolioService?.payment?.lineItems && portfolioService.payment.lineItems.length > 0">
				<span class="line-items-container" *ngFor="let lineItem of portfolioService.payment.lineItems">
						<span>{{lineItem.Description}}</span>
						<span>{{formatCurrency(lineItem.Amount)}}</span>
				</span>
			</ng-container>
			<hr>
			<div class="line-items-container total-line-item">
				<span>Total</span>
				<span>{{formatCurrency(portfolioService.payment?.amount)}}</span>
			</div>
			<div class="row">
				<div class="col-sm-12 loan-app-form-control">
					<label id="nameOnCardLabel" for="nameOnCardInput">Name on Card</label>
					<input id="nameOnCardInput" placeholder="Name on Card" class="form-control" autocomplete="cc-name"
					       inputmode="text" maxlength="64"
					       [(ngModel)]="portfolioService.payment.nameOnCard"
					       [autoValidate]="portfolioService.payment" autoValidatePropertyKey="nameOnCard"
					       aria-label="First Name" />
				</div>
			</div>
			<div class="row">
				<div class="col-sm-8 loan-app-form-control">
					<label id="cardNumberLabel" for="cardNumberInput">Credit Card Number</label>
					<input id="cardNumberInput" placeholder="Credit Card Number" class="form-control" autocomplete="cc-number"
					       (keyup)="setCardType()" inputmode="tel"
					       [textMask]="cardType === 'amex' ? lookups.TextMasks.creditCardNumberAmEx : lookups.TextMasks.creditCardNumber"
					       [(ngModel)]="portfolioService.payment.creditCardNumber" #creditCardNumberInput
					       [autoValidate]="portfolioService.payment" autoValidatePropertyKey="creditCardNumber"
					       aria-label="Credit Card Number" />
				</div>
				<div class="col-sm-4 loan-app-form-control">
					<label id="cvvLabel" for="cvvInput">CVV</label>
					<input id="cvvInput" placeholder="CVV Code" class="form-control"
					       maxlength="4" inputmode="tel" #cvvInput
					       [(ngModel)]="portfolioService.payment.cvv"
					       [autoValidate]="portfolioService.payment" autoValidatePropertyKey="cvv"
					       aria-label="CVV" />
				</div>
			</div>
			<div class="row">
				<div class="col-sm-6 loan-app-form-control">
					<label id="expirationDateLabel" for="expirationDateInput">Expiration Date</label>
					<input id="expirationDateInput" placeholder="MM/YY" class="form-control" autocomplete="cc-exp"
					       (input)="validateExpirationDateInput($event)"
					       (focus)="onFocusExpirationDateInput()"
					       [textMask]="lookups.TextMasks.monthAndYearDate" inputmode="tel"
					       [(ngModel)]="portfolioService.payment.expirationDate"
					       [autoValidate]="portfolioService.payment" autoValidatePropertyKey="expirationDate"
					       aria-label="Expiration Date" />
				</div>
				<div class="col-sm-6 loan-app-form-control">
					<label id="zipCodeLabel" for="zipCodeInput">Zip Code</label>
					<input id="zipCodeInput" placeholder="Zip Code" class="form-control" autocomplete="postal-code"
					       [textMask]="lookups.TextMasks.zip" inputmode="tel"
					       [(ngModel)]="portfolioService.payment.zipCode"
					       [autoValidate]="portfolioService.payment" autoValidatePropertyKey="zipCode"
					       aria-label="Zip Code" />
				</div>
			</div>
			<div class="row">
				<div class="col-sm-12 loan-app-form-control helper-text-wrapper helper-text align-left">
					<div class="helper-text">{{portfolioService.payment.disclaimer}}</div>
				</div>
			</div>
		</div>
	</div>
	<div id="payment-form-overlay" class="payment-form-overlay"
			*ngIf="portfolioService.showProgressAnimation || portfolioService.showPaymentSuccessMessage || portfolioService.showPaymentFailureMessage">
	</div>
</div>
<div *ngIf="isStatusMessageVisible()" class="payment-form-container">
	<div *ngIf="portfolioService.showProgressAnimation" class="payment-status-card">
		<img src="/assets/img/animated_credit_card_loading.svg"
				alt="progress animation showing a credit card being swiped" /><br />
		<span>Payment processing...</span>
	</div>
	<div *ngIf="portfolioService.showPaymentSuccessMessage" class="payment-status-card">
		<i class="far fa-check-circle payment-succeeded-icon"
			alt="a green check mark indicating that the payment was successful"></i>
		<br /><br />
		<span>Payment complete!</span>
	</div>
	<div *ngIf="portfolioService.showPaymentFailureMessage" class="payment-status-card">
		<i class="far fa-times-circle payment-failed-icon"
			alt="a red check mark indicating that the payment failed"></i>
		<br /><br />
		<span id="payment-failure-message">Payment failed. Please try again later.</span>
	</div>
</div>
<div class="nbkc-card-section">
	<div class="row align-right">
		<lib-button card-footer-button class="material-button-no-padding lib-button lib-right-action"
					[id]="'cancelPaymentButton'"
					[displayText]="portfolioService.showPaymentSuccessMessage || portfolioService.showPaymentFailureMessage ? 'Close' : 'Cancel'"
					(clickEvent)="hidePaymentRequestDialog()"
					[isDisabled]="portfolioService.showProgressAnimation"
					aria-label="Cancel payment button">
		</lib-button>
		<lib-button card-footer-button class="material-button-no-padding lib-button lib-right-action"
					[id]="'payNowButton'"
					[displayText]="'Pay Now'"
					(clickEvent)="portfolioService.getCreditCardToken()"
					[isDisabled]="portfolioService.isPaymentMessageShowing() || !this.portfolioService.payment?.isValid()?.valid"
					aria-label="Pay now button">
		</lib-button>
	</div>
</div>
