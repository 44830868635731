import { AdapterBase } from '@utilities/adapter-base';
import { IBorrowerDeclarationsForm } from '@models/form/declarations/borrower-declarations';
import { LiabilityApi } from '@models/api/liability';
import numeral from 'numeral';

export class BorrowerDeclarationsFormToLiabilityApiAdapter extends AdapterBase<IBorrowerDeclarationsForm, LiabilityApi[]> {
	adapt(source: IBorrowerDeclarationsForm): LiabilityApi[] {
		const dest: LiabilityApi[] = [];
		if (source.haveAlimonyPayments) {
			dest.push(new LiabilityApi('Alimony', numeral(source.alimonyPayments).value()));
		}
		if (source.haveChildSupportPayments) {
			dest.push(new LiabilityApi('ChildSupport', numeral(source.childSupportPayments).value()));
		}
		return dest;
	}
}
