import { Detail } from "../../api/borrower-portal/detail";
import { Documentation } from "../../api/borrower-portal/documentation";
import { Origination } from "../../api/borrower-portal/origination";
import { PortfolioItemSource } from "../../api/borrower-portal/portfolio-item-source";
import { Reason } from "../../api/borrower-portal/reason";

export class PortfolioRequestForm {
	public description: string;
    public displayName: string;
    public documentation: Documentation;
	public id: string;
    public scopeId: string; 
    public scopeType: string;
	public origination: Origination;
	public responsibility: string;
	public status: string;
    public detail: Detail;
    public reason: Reason;
    public type: string;
    public source: PortfolioItemSource
    public icon: string;
}