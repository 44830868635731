export class AddressHelper {
	public static format(geoData: AddressLikeObject): any {
		if (geoData) {
			const addressItem = geoData;
			if (addressItem) {
				let addressLine = '';
				if (addressItem.Address1 != null) {
					addressLine = addressItem.Address1.trim();
				}
				if (addressItem.Address2 != null) {
					if (addressItem.Address1 != null) {
						addressLine = addressLine + ' ';
					}
					addressLine = addressLine + addressItem.Address2.trim();
				}
				let freeFormAddress = '';

				if (addressLine != null && addressLine !== '') {
					freeFormAddress = addressLine;
				}

				if (addressItem.City != null) {
					if (freeFormAddress != null && freeFormAddress !== '') {
						freeFormAddress = freeFormAddress + ', ';
					}
					freeFormAddress = freeFormAddress + addressItem.City;
				}

				if (addressItem.StateCode != null) {
					if (freeFormAddress != null && freeFormAddress !== '') {
						freeFormAddress = freeFormAddress + ', ';
					}
					freeFormAddress = freeFormAddress + addressItem.StateCode;
				}

				if (addressItem.Zip != null) {
					if (freeFormAddress != null && freeFormAddress !== '') {
						freeFormAddress = freeFormAddress + ' ';
					}
					freeFormAddress = freeFormAddress + addressItem.Zip;
				}

				return {
					address1: addressLine,
					city: addressItem.City,
					state: addressItem.StateCode,
					zip: addressItem.Zip,
					county: addressItem.County,
					countryCode: addressItem.CountryCode,
					full: freeFormAddress
				};
			} else {
				return {
					address1: '',
					city: '',
					state: '',
					zip: '',
					county: '',
					countryCode: '',
					full: ''
				};
			}
		}
	}
}

export interface AddressLikeObject {
	Address1?: string;
	Address2?: string;
	City?: string;
	StateCode?: string;
	Zip?: string;
	County?: string;
	CountryCode?: string;
}
