import { AdapterBase } from '@utilities/adapter-base';
import { UserProfile } from '@models/user-profile.model';
import { ProfileApi } from '@models/api/profile.model';

export class ProfileApiToUserProfileAdapter extends AdapterBase<ProfileApi, UserProfile> {
	public adapt(source: ProfileApi): UserProfile {
		const profile = new UserProfile();
		profile.FirstName = source.FirstName;
		profile.LastName = source.LastName;
		profile.PrimaryPhone = source.PrimaryPhone;
		profile.EmailAddress = source.EmailAddress;
		profile.Roles = source.Roles;
		profile.LoanApplicationUrl = source.LoanApplicationUrl;

		return profile;
	}
}
