export class SectionNames {
	public static loan: string = 'loan';
	public static borrowers: string = 'borrowers';
	public static residenceHistory: string = 'residence-history';
	public static employment: string = 'employment';
	public static otherIncome: string = 'income';
	public static assets: string = 'assets';
	public static realEstate: string = 'real-estate';
	public static declarations: string = 'declarations';
	public static demographics: string = 'demographics';
	public static review: string = 'review';
}
