
import { Document } from "@models/api/borrower-portal/document";

export class FileBatch {
	DisplayName: string
	IsComplete: boolean = false;
	HasError: boolean = false;
	ErrorMessage?: string
	CompletionPercent: number = 0;
	Files: Document[];
}