import { Component } from "@angular/core";

export abstract class CardHeaderToken {}

@Component({
  selector: 'lib-card-header-component',
  templateUrl: './card-header.component.html',
  providers: [
    {provide: CardHeaderToken, useExisting: CardHeaderComponent}
  ]
})
 export class CardHeaderComponent extends CardHeaderToken {
    constructor() {
        super();
    }
 }