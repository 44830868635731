import { AdapterBase } from '@utilities/adapter-base';
import { AddressForm } from '@models/form/address.model';
import { AddressApi } from '@models/api/address';

export class AddressFormToAddressApiAdapter extends AdapterBase<AddressForm, AddressApi> {
	adapt(source: AddressForm): AddressApi {
		const dest = new AddressApi();
		dest.AddressLine1 = source.address1;
		dest.State = source.state;
		dest.City = source.city;
		dest.PostalCode = source.zip;
		dest.UnitType = source.unitType;
		dest.UnitNumber = source.unitNumber;
		dest.County = source.county;
		dest.CountryCode = source.countryCode;
		return dest;
	}
}
