export const NEW_PRODUCT_CARDS = {
    Purchase: {
        icon: 'fa fa-home',
        header: 'Start a new Purchase Application',
        content: 'You are purchasing a new home.',
        purpose: 'Purchase'
    },
    Refinance: {
        icon: 'fa fa-balance-scale',
        header: 'Start a new Refinance Application',
        content: 'You are refinancing on an existing home to get a lower rate and/or to take equity out of your home.',
        purpose: 'Refinance'
    },
    HELOC: {
        icon: 'fa fa-piggy-bank',
        header: 'Start a new HELOC Application',
        content: 'You want to tap into your existing home equity to make improvements, consolidate debt, or make a purchase.',
        purpose: 'HELOC'
    }
} as const; 