import { Component } from '@angular/core';
import { AppState } from '@services/app-state.service';

@Component({
	selector: 'app-main-loader',
	templateUrl: './main-loader.component.html',
	styleUrls: ['./main-loader.component.scss']
})
export class MainLoaderComponent {
	constructor(public appState: AppState) { }
}
