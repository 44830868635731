import { AdapterBase, AdapterOptions } from '@utilities/adapter-base';
import { LinkedItemApiToLinkedAccountFormAdapter } from './linked-item-api-to-linked-account-form.adapter';
import { DateHelper } from '@utilities/helpers';
import { LinkedAccountApi } from '@models/api/linked-account';
import { LinkedAccountFormCollection } from '@models/form/linked-account-collection.model';

export class LinkedAccountApiToLinkedAccountFormCollectionAdapter extends AdapterBase<LinkedAccountApi, LinkedAccountFormCollection> {
	private _linkedItemApiToLinkedAccountFormAdapter: LinkedItemApiToLinkedAccountFormAdapter = new LinkedItemApiToLinkedAccountFormAdapter();

	adapt(source: LinkedAccountApi, options?: AdapterOptions): LinkedAccountFormCollection {
		const dest = new LinkedAccountFormCollection();
		dest.id = source.LinkedAccountId
		dest.name = source.AccountName;
		dest.type = source.AccountType;
		dest.sourceId = source.LinkSourceId;
		dest.source = source.LinkSource;
		dest.publicToken = source && source.LinkProperties && source.LinkProperties.public_token;
		dest.day1CertaintyToken = source && source.LinkProperties && source.LinkProperties.day1_certainty_token;
		dest.status = source.LinkStatus;
		dest.linkedByUser = source.LinkedByUserId;
		dest.dateLinked = DateHelper.dateToString(source.DateLinked);
		dest.dateUpdated = DateHelper.dateToString(source.DateUpdated);
		if (source.LinkedItems && source.LinkedItems.length > 0) {
			dest.accounts = this._linkedItemApiToLinkedAccountFormAdapter.adaptCollection(source.LinkedItems);
		}
		return dest;
	}
}
