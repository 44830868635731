import { Component } from "@angular/core";

export abstract class CardFooterToken {}

@Component({
  selector: 'lib-card-footer',
  templateUrl: './card-footer.component.html',
  styleUrls: ['./card-footer.component.scss'],
  providers: [
    {provide: CardFooterToken, useExisting: CardFooterComponent}
  ]
})
export class CardFooterComponent extends CardFooterToken {

}