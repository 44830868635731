import { IdDescriptionPair } from './lookups';

export const PropertyUsageTypes: IdDescriptionPair[] = [
	{
		id: null,
		description: ''
	}, {
		id: 'PrimaryResidence',
		description: 'Primary Residence'
	},
	{
		id: 'SecondHome',
		description: 'Second Home'
	},
	{
		id: 'InvestmentProperty',
		description: 'Investment Property'
	}
];
