import { AdapterBase, AdapterOptions } from '@utilities/adapter-base';
import { DemographicAnswer } from '@models/form/demographics/demographic-helpers/demographic-answer.model';
import { DemographicApi } from '@models/api/demographic';
import { Lookups } from '@lookups/lookups';

export class DemographicAnswerToDemographicApiAdapter extends AdapterBase<DemographicAnswer<any>, DemographicApi> {
	adapt(source: DemographicAnswer<any>, options: CurrentAdapterOptions): DemographicApi {
		const demographicLookUp = Lookups.DemographicsCheckOptions;
		const demographic = new DemographicApi();
		demographic.DemographicCategory = demographicLookUp[options.key].compositeId.category;
		demographic.DemographicName = demographicLookUp[options.key].compositeId.displayName;
		if (demographicLookUp[options.key].freeForm === true) {
			demographic.CustomText = source.value;
		}
		return demographic;
	}
}

interface CurrentAdapterOptions extends AdapterOptions {
	key: string;
}
