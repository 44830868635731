import { AdapterBase } from '@utilities/adapter-base';
import { LinkedItemApi } from '@models/api/linked-item';
import { LinkedAccountForm } from '@models/form/linked-account.model';

export class LinkedItemApiToLinkedAccountFormAdapter extends AdapterBase<LinkedItemApi, LinkedAccountForm> {
	adapt(source: LinkedItemApi): LinkedAccountForm {
		const dest = new LinkedAccountForm();
		dest.linkId = source.LinkId;
		dest.name = source.Name;
		dest.accountType = source.Type;
		dest.accountNumber = source.Description;
		return dest;
	}
}
