import { AdapterBase } from '@utilities/adapter-base';
import { LinkedAccountForm } from '@models/form/linked-account.model';
import { LinkedItemApi } from '@models/api/linked-item';

export class LinkedAccountFormToLinkedItemApiAdapter extends AdapterBase<LinkedAccountForm, LinkedItemApi> {
	adapt(source: LinkedAccountForm): LinkedItemApi {
		const dest = new LinkedItemApi();
		dest.LinkId = source.linkId;
		dest.Description = source.accountNumber;
		dest.Type = source.accountType;
		dest.Name = source.name;
		return dest;
	}

}
