import { SectionProgress } from './form/progress/section.progress.model';
import { QualificationResult } from './form/qualification-result.model';

export class Section {
	public name: string;
	public displayName: string;
	public modelName: string;
	public summaryComponent: any;
	public progress: SectionProgress;
	public disabled: boolean;
	public hiddenFromNavigation: boolean;
	public hiddenFromReviewPage: boolean;
	public showNavigation: boolean;
	public businessRules: QualificationResult[];

	constructor(props: any = { }) {
		this.progress = new SectionProgress();
		if (props) {
			this.name = props.name || '';
			this.displayName = props.displayName || '';
			this.modelName = props.modelName || '';
			this.summaryComponent = props.summaryComponent || '';
			this.progress = props.progress || this.progress;
			this.disabled = props.disabled || false;
			this.hiddenFromNavigation = props.hiddenFromNavigation || false;
			this.hiddenFromReviewPage = props.hiddenFromReviewPage || false;
			this.showNavigation = props.showNavigation !== false;
		}
	}
}
