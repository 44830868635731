import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class BorrowerPortalState {
	public preApprovalFormShown: boolean;

	constructor() {

	}

}
