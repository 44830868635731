import { AdapterBase } from '@utilities/adapter-base';
import { RealEstateForm } from '@models/form/real-estate.model';
import { ReoPropertyApi } from '@models/api/reo-property';
import numeral from 'numeral';
import { AddressFormToAddressApiAdapter } from './address-form-to-address-api.adapter';

export class RealEstateFormToReoPropertyApiAdapter extends AdapterBase<RealEstateForm, ReoPropertyApi> {
	private _addressFormToAddressApiAdapter: AddressFormToAddressApiAdapter = new AddressFormToAddressApiAdapter();

	adapt(source: RealEstateForm): ReoPropertyApi {
		const destination = new ReoPropertyApi();
		destination.Address = this._addressFormToAddressApiAdapter.adapt(source.address);
		destination.PropertyUsageType = source.propertyUsageType as string;
		return destination;
	}
}
