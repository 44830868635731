import { Observable } from 'rxjs';

export class DynamicConfig<T> {
	private readonly configurationEndpoint: string;

	public constructor(configurationEndpoint: string) {
		this.configurationEndpoint = configurationEndpoint;
	}

	public configurationValues$: Observable<T> = new Observable<T>((observer) => {
		const xhr = new XMLHttpRequest();
		xhr.open('GET', this.configurationEndpoint);
		xhr.onload = () => {
			if (xhr.status === 200) {
				observer.next(JSON.parse(xhr.responseText));
			} else {
				observer.error('Could not get application configuration! ' + xhr.status);
			}
			observer.complete();
		};
		xhr.send();
	});
}
