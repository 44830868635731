import {
	TrackerEvent,
	ApplicationInsightsProviderMapping,
	GoogleAnalyticsProviderMapping,
	ApplicationInsightsEvent,
	GoogleAnalyticsEvent
} from '@nbkc/tracker';

import { ApplicationTrackerContext } from '../application-tracker-context';

export class SectionCompletedEvent extends TrackerEvent<ApplicationTrackerContext>
	implements ApplicationInsightsProviderMapping, GoogleAnalyticsProviderMapping {
	public name: string = 'section-completed';

	public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = `${this.trackerContext.sectionName}-completed`;
		return event;
	}

	public ga(): GoogleAnalyticsEvent {
		const event = new GoogleAnalyticsEvent();
		event.action = 'Click';
		event.category = 'Application';
		event.label = 'Continue';
		return event;
	}
}
