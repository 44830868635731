import { PortfolioTypes } from "@constants";
import { ProductAvailabilityApi } from "@models/api/product-availability-api";
import { ProductView } from "@models/product-view.model";
import { AdapterBase } from "@utilities/adapter-base";

export class ProductAvailabilityApiToProductViewAdapter extends AdapterBase<ProductAvailabilityApi, ProductView> {
	public adapt(product: ProductAvailabilityApi): ProductView {
		const preview = new ProductView();
		
		preview.channel = product.Channel;
		preview.name = product.Name; 
		preview.type = PortfolioTypes.matchPurpose(product.Type);

		return preview;
	}
}