import {
	TrackerEvent,
	ApplicationInsightsProviderMapping,
	ApplicationInsightsEvent
} from '@nbkc/tracker';
import { ApplicationTrackerContext } from '../application-tracker-context';

export class ESignViewPackageEvent extends TrackerEvent<ApplicationTrackerContext>
	implements ApplicationInsightsProviderMapping {
	public name: string = 'Esign-view-now-button-click';

	public constructor(public data: ESignViewPackageEventProps) {
		super();
	}

	public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = this.name;
		event.data = {
			applicationId: this.trackerContext.applicationId,
			portfolioId: this.data.portfolioId,
			packageName: this.data.packageName,
			borrowerName: this.data.borrowerName
		};

		return event;
	}
}

interface ESignViewPackageEventProps {
	portfolioId: string;
	borrowerName: string;
	packageName: string;
}
