import { Validatable, Validate, ValidateIf, Validators } from '@nbkc/validation';
import { AddressForm } from './address.model';
import { ContactForm } from './contact-form.model';
import { LoanOfficerForm } from './loan-officer-form.model';
import { PortfolioTypes } from '../../constants/portfolio-types';

export class LoanForm extends Validatable {
	@Validate(Validators.required('Please enter a loan amount.'))
	public amount: string;
	@ValidateIf(`this.purpose === 'Purchase'`, Validators.requiredZeroIsValid('Please enter a down payment.'))
	public downPaymentAmount: string | null;
	public downPaymentPercent: string;
	@ValidateIf(`this.purpose === 'HELOC'`, Validators.required('Please enter an outstanding mortgage amount.'))
	public outstandingMortgage: string;
	@ValidateIf(`this.purpose === 'Refinance' || this.purpose === 'HELOC'`, Validators.required())
	public propertyAddress: AddressForm;
	@Validate(Validators.currency())
	public estimatedValueOrPurchasePrice: string;
	@Validate(Validators.required('Please choose an occupancy type.'))
	public occupancyType: string | null;
	@Validate(Validators.watch())
	public purpose: string;
	public cashOut: boolean;
	public hasPreferredLoanOfficer: boolean;
	public hasRealEstateAgent: boolean;
	@ValidateIf('this.hasPreferredLoanOfficer===true', Validators.required('Please select a loan officer'))
	public preferredLoanOfficer: LoanOfficerForm;
	@ValidateIf('this.hasRealEstateAgent===true', Validators.required())
	public realEstateAgent: ContactForm;
	@ValidateIf(`this.purpose === 'Purchase'`, Validators.required('Please choose a state.'))
	public state: string | null;

	constructor(props: any = { }) {
		super();
		this.propertyAddress = new AddressForm();
		this.amount = '0';
		this.downPaymentPercent = '';
		this.downPaymentAmount = '0';
		this.outstandingMortgage = '0';
		this.estimatedValueOrPurchasePrice = '0';
		this.occupancyType = '';
		this.purpose = '';
		this.cashOut = false;
		this.propertyAddress = props.propertyAddress || '';
		this.realEstateAgent = new ContactForm();
		this.state = '';
	}

	public get isCashOutRefinance(): boolean {
		return this.cashOut === true && this.isRefinance;
	}

	public get isPurchase(): boolean {
		return this.purpose === PortfolioTypes.purchase;
	}

	public get isRefinance(): boolean {
		return this.purpose === PortfolioTypes.refinance;
	}

	public get isHELOC(): boolean {
		return this.purpose === PortfolioTypes.heloc;
	}
}
