import { createAutoCorrectedDatePipe, createNumberMask, emailMask } from 'text-mask-addons/dist/textMaskAddons';

const pipes = {
	// @ts-ignore
	// Type Definition file contains a bug. This signature is correct.
	datePipe: createAutoCorrectedDatePipe('mm/dd/yyyy HH:MM', { minYear: 1900, maxYear: new Date().getFullYear() }),
	// @ts-ignore
	datePipeFuture: createAutoCorrectedDatePipe('mm/dd/yyyy HH:MM', { minYear: new Date().getFullYear() })
};

const customMaskDefinitions = {
	percentMaskNoDecimalThreeDigit: createNumberMask({
		suffix: '%',
		prefix: '',
		allowDecimal: false,
		integerLimit: 3,
	}),
	percentMaskTwoDecimalTwoDigit: createNumberMask({
		suffix: '%',
		prefix: '',
		allowDecimal: true,
		integerLimit: 2,
		decimalLimit: 2,
	}),
	currencyMask: createNumberMask({
		prefix: '$',
		allowDecimal: true,
		integerLimit: 8,
	}),
	currencyNoDecimalsMask: createNumberMask({
		prefix: '$',
		allowDecimal: false,
		integerLimit: 8,
	})
};

const TextMaskConfigs = {
	percentMaskTwoDecimalTwoDigitConfig: { mask: customMaskDefinitions.percentMaskTwoDecimalTwoDigit, placeholderChar: '\u2000' },
	percentMaskNoDecimalThreeDigitConfig: { mask: customMaskDefinitions.percentMaskNoDecimalThreeDigit, placeholderChar: '\u2000' },
	singleLetterMaskConfig: {
		mask: [/[a-z]/i],
		placeholderChar: '\u2000',
	},
	emailMaskConfig: {
		mask: emailMask,
	},
	decimalMaskConfig: {
		mask: createNumberMask({ prefix: '', allowDecimal: true }),
		placeholderChar: '\u2000',
	},
	numberMaskConfig: {
		mask: createNumberMask({ prefix: '', allowDecimal: false }),
		placeholderChar: '\u2000',
	},
	zipMaskConfig: {
		mask: createNumberMask({
			prefix: '', allowDecimal: false, integerLimit: 5, allowLeadingZeroes: true, includeThousandsSeparator: false
		}),
		placeholderChar: '\u2000'
	},
	ageMaskConfig: {
		mask: createNumberMask({ prefix: '', allowDecimal: false, integerLimit: 2 }),
		placeholderChar: '\u2000',
	},
	fourDigitCurrencyConfig: {
		mask: createNumberMask({ prefix: '$', allowDecimal: true, integerLimit: 4 }),
		placeholderChar: '\u2000',
	},
	phoneMaskConfig: {
		mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
		placeholderChar: '\u2000',
	},
	ssnMaskConfig: {
		mask: [/[\d|*]/, /[\d|*]/, /[\d|*]/, '-', /[\d|*]/, /[\d|*]/, '-', /[\d|*]/, /[\d|*]/, /[\d|*]/, /[\d|*]/],
		placeholderChar: '\u2000',
		guide: false,
	},
	currencyMaskConfig: {
		mask: customMaskDefinitions.currencyMask,
		placeholderChar: '\u2000',
	},
	currencyNoDecimalsMaskConfig: {
		mask: customMaskDefinitions.currencyNoDecimalsMask,
		placeholderChar: '\u2000',
	},
	dateMaskConfig: {
		mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
		pipe: pipes.datePipe,
		keepCharPositions: true,
		placeholderChar: '\u2000',
	},
	dateMaskFutureConfig: {
		mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
		pipe: pipes.datePipeFuture,
		keepCharPositions: true,
		placeholderChar: '\u2000',
	},
	creditCardNumberMaskConfig: {
		mask: [/[\d*]/, /[\d*]/, /[\d*]/, /[\d*]/, ' ', /[\d*]/, /[\d*]/, /[\d*]/, /[\d*]/, ' ', /[\d*]/, /[\d*]/, /[\d*]/, /[\d*]/, ' ', /[\d*]/, /[\d*]/, /[\d*]/, /[\d*]/],
		placeholderChar: '\u2000'
	},
	creditCardNumberAmExMaskConfig: {
		mask: [/[\d*]/, /[\d*]/, /[\d*]/, /[\d*]/, ' ', /[\d*]/, /[\d*]/, /[\d*]/, /[\d*]/, /[\d*]/, /[\d*]/, ' ', /[\d*]/, /[\d*]/, /[\d*]/, /[\d*]/, /[\d*]/],
		placeholderChar: '\u2000'
	},
	creditCardCvvMaskConfig: {
		mask: [/[\d*]/, /[\d*]/, /[\d*]/, /[\d*]/],
		placeholderChar: '\u2000'
	},
	monthAndYearDateMaskConfig: {
		mask: [/[0,1]/, /\d/, '/', /[2-4]/, /\d/],
		keepCharPositions: true,
		placeholderChar: '\u2000'
	}
};

export const TextMasks = {
	currency: TextMaskConfigs.currencyMaskConfig,
	currencyNoDecimals: TextMaskConfigs.currencyNoDecimalsMaskConfig,
	date: TextMaskConfigs.dateMaskConfig,
	dateFuture: TextMaskConfigs.dateMaskFutureConfig,
	decimal: TextMaskConfigs.decimalMaskConfig,
	email: TextMaskConfigs.emailMaskConfig,
	number: TextMaskConfigs.numberMaskConfig,
	phone: TextMaskConfigs.phoneMaskConfig,
	ssn: TextMaskConfigs.ssnMaskConfig,
	age: TextMaskConfigs.ageMaskConfig,
	fourDigitCurrency: TextMaskConfigs.fourDigitCurrencyConfig,
	percentTwoDecimalTwoDigit: TextMaskConfigs.percentMaskTwoDecimalTwoDigitConfig,
	percentNoDecimalThreeDigit: TextMaskConfigs.percentMaskNoDecimalThreeDigitConfig,
	zip: TextMaskConfigs.zipMaskConfig,
	singleLetter: TextMaskConfigs.singleLetterMaskConfig,
	creditCardNumber: TextMaskConfigs.creditCardNumberMaskConfig,
	creditCardNumberAmEx: TextMaskConfigs.creditCardNumberAmExMaskConfig,
	creditCardCvv: TextMaskConfigs.creditCardCvvMaskConfig,
	monthAndYearDate: TextMaskConfigs.monthAndYearDateMaskConfig
};

export interface AvailableTextMasks {
	currency: any;
	currencyNoDecimals: any;
	date: any;
	dateFuture: any;
	decimal: any;
	email: any;
	number: any;
	phone: any;
	ssn: any;
	age: any;
	fourDigitCurrency: any;
	percentNoDecimalThreeDigit: any;
	percentTwoDecimalTwoDigit: any;
	zip: any;
	singleLetter: any;
	creditCardNumber: any;
	creditCardNumberAmEx: any;
	creditCardCvv: any;
	monthAndYearDate: any;
}
