import { Directive, HostBinding, HostListener } from '@angular/core';
import { ApplicationService } from '@services/application.service';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[click]'
})
export class ClickHelperDirective {
	constructor(public applicationService: ApplicationService) { }
	@HostBinding('class.clickable') clickable: boolean = true;
}
