import { Component, Input } from "@angular/core";


@Component({
    selector: 'lib-card-component',
    templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss']
  })
  export class CardComponent {
  @Input() headerTitle: string = null
  @Input() headerSubTitle: string = null;
  @Input() headerSecondSubTitle: string = null;
  @Input() headerIcon: string = null;
  @Input() headerRightIcon?: string = null;
  @Input() contentBody: string = null;
  @Input() hasCornerBanner?: boolean = false;

    constructor(){
    }
  }