import { AdapterBase } from '@utilities/adapter-base';
import { BorrowerDemographicForm } from '@models/form/demographics/borrower-demographics.model';
import { DemographicApi } from '@models/api/demographic';
import { DemographicAnswer } from '@models/form/demographics/demographic-helpers/demographic-answer.model';
import _ from 'lodash';
import { DemographicAnswerToDemographicApiAdapter } from './demographic-answer-to-demographic-api.adapter';
import { DemographicSection } from '@models/form/demographics/demographic-sections/demographic-section.model';

export class BorrowerDemographicFormToDemographicApiCollectionAdapter extends AdapterBase<BorrowerDemographicForm, DemographicApi[]> {
	private _demographicAnswerToDemographicApiAdapter: DemographicAnswerToDemographicApiAdapter = new DemographicAnswerToDemographicApiAdapter();

	adapt(source: BorrowerDemographicForm): DemographicApi[] {
		let destination: DemographicApi[] = [];
		destination = destination.concat(this.adaptDemographicSection(source.sex));
		destination = destination.concat(this.adaptDemographicSection(source.ethnicity));
		destination = destination.concat(this.adaptDemographicSection(source.race));
		return destination;
	}

	private adaptDemographicSection(source: DemographicSection): DemographicApi[] {
		const out: DemographicApi[] = [];
		_.forIn(source, (demographic, key) => {
			if (demographic instanceof DemographicAnswer && demographic.value) {
				out.push(this._demographicAnswerToDemographicApiAdapter.adapt(demographic, { key }));
			}
		});
		return out;
	}
}
