import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MaintenanceService } from '@services/maintenance.service';

@Injectable({
	providedIn: 'root'
})
export class MaintenanceInterceptor implements HttpInterceptor {

	constructor(public maintenanceService: MaintenanceService) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap((response) => {
				if (response instanceof HttpResponse && response && response.body && response.body.Maintenance) {
					this.maintenanceService.setMaintenanceStatus(response.body.Maintenance);
				}
			})
		);
	}
}
