<app-app-header></app-app-header>
<div class="large-screen-container">
	<div class="divider-container">
		<div class="left-column flex-1">
				<div class="flex-grow ml-4 mt-1 main-title-container">
					<span class="product-title">Welcome to nbkc bank!</span>
					<div class="subtitle-container">
						<span class="subtitle">Let's get your new application started!</span>
					</div>
				</div>
				<div class="my-applications-container" role="main" aria-labelledby="inProgressApplicationsTitle">
					<div class="separator-container">
						<div *ngIf="inProgressApps?.length > 0 || submittedApps.length > 0" class="flex items-center text-center separator my-4 mx-0">
							<span id="inProgressApplicationsTitle" class="divider-text">Loans in Progress</span>
						</div>
					</div>
					<div  *ngIf="inProgressApps?.length > 0">
						<div *ngFor="let app of inProgressApps; index as $index"
							[ngClass]="{'no-border': $index === (inProgressApps.length - 1)}">
							<lib-card-component 
								[headerIcon]="getLoanIcon(app.portfolioType)"
								[headerTitle]="getLoanDisplayText(app)" 
								[headerSubTitle]="'Started on ' + grammarService.convertUtcToLocal(app.createDate).format('MMMM D, YYYY')"
								[headerSecondSubTitle]="'Pending Application'">
								<lib-button card-footer-button class="material-button-no-padding"
									[id]="'continueapplicationButton{{$index}}'" [displayText]="'Continue your application'"
									(click)="viewApp(app)">
								</lib-button>
							</lib-card-component>
						</div>
					</div>
					<div *ngIf="submittedApps.length > 0">
						<div *ngFor="let app of submittedApps; index as $index"
							[ngClass]="{'no-border': $index === (submittedApps.length - 1)}">
							<lib-card-component 
								[headerIcon]="getLoanIcon(app.portfolioType)"
								[headerTitle]="getLoanDisplayText(app)" 
								[headerSubTitle]="'Started on ' + grammarService.convertUtcToLocal(app.createDate).format('MMMM D, YYYY')"
								[headerSecondSubTitle]="'Loan Number:  ' + app.loanNumber">
								<lib-button card-footer-button class="material-button-no-padding"
									[id]="'submittedApplicationButton{{$index}}'" [displayText]="'Go to Borrower Portal'"
									(click)="viewSubmittedApp(app)">
								</lib-button>
							</lib-card-component>
						</div>
					</div>	
					<div class="nbkc-card-section">
						<div class="separator-container">
							<div class="flex items-center text-center separator my-4 mx-0">
								<span id="newApplicationsTitle" class="divider-text">Start a new Application</span>
							</div>
						</div>
						<lib-card-component *ngFor="let product of products; index as $productIndex"
							[headerIcon]="NEW_PRODUCT_CARDS[product.type].icon"
							[headerTitle]="NEW_PRODUCT_CARDS[product.type].header" 
							[contentBody]="NEW_PRODUCT_CARDS[product.type].content">
							<lib-button card-footer-button 
								class="material-button-no-padding"
								[id]="'lib-right-action'" 
								[displayText]="'Start an Application'"
								(click)="startCreateNewApplicationFlow(NEW_PRODUCT_CARDS[product.type].purpose)">
							</lib-button>
						</lib-card-component>
					</div>
				</div>
		</div>
		<div class = "right-column flex-intial">
			<img class="divider-image sticky-position " src="assets/img/landing-page-welcome-mat.jpg">
		</div>
	</div>
</div>


