import { IdDescriptionPair } from './lookups';

export const MaritalStatuses: IdDescriptionPair[] = [
	{
		id: null,
		description: ''
	},
	{
		id: 'Unmarried',
		description: 'Unmarried'
	},
	{
		id: 'Married',
		description: 'Married'
	},
	{
		id: 'Separated',
		description: 'Separated'
	}
];
