import { AdapterBase } from '@utilities/adapter-base';
import { ResidenceApi } from '@models/api/residence';
import { ResidenceForm } from '@models/form/residence.model';
import { AddressFormToAddressApiAdapter } from './address-form-to-address-api.adapter';
import numeral from 'numeral';
export class ResidenceFormToResidenceApiAdapter extends AdapterBase<ResidenceForm, ResidenceApi> {
	private _addressFormToAddressApiAdapter: AddressFormToAddressApiAdapter = new AddressFormToAddressApiAdapter();

	adapt(source: ResidenceForm): ResidenceApi {
		const dest = new ResidenceApi();
		dest.Address = this._addressFormToAddressApiAdapter.adapt(source.address);
		if (!source.mailingAddressSameAsResidence) {
			dest.MailingAddress = this._addressFormToAddressApiAdapter.adapt(source.mailingAddress);
		} else {
			dest.MailingAddress = this._addressFormToAddressApiAdapter.adapt(source.address);
		}
		dest.MailingAddressDifferent = !source.mailingAddressSameAsResidence;
		dest.DurationTermMonths = source.duration;
		dest.IsCurrent = source.isCurrent;
		dest.PropertyOwned = source.isOwned;
		dest.RentalAmount = numeral(source.rentAmount).value();
		return dest;
	}
}
