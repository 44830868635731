import _ from 'lodash';

export class QualificationResult {
	public description: string;
	public suggestion: string;
	public passed: boolean;
	public tags: string[];
	public updated: boolean;
	public borrowerIndex: string | null;

	constructor(data: QualificationResultParams) {
		this.description = data.description;
		this.passed = data.passed;
		this.tags = data.tags;
		const result = _.find(data.tags, (tag: string) => (tag.split('.')[0] === 'BorrowerIndex'));
		this.borrowerIndex = (result) ? result.split('.')[1] : null;
		this.updated = false;
		this.suggestion = data.suggestion;
	}
}

interface QualificationResultParams {
	description: string;
	passed?: boolean;
	tags: string[];
	suggestion: string;
}
