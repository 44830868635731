import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import numeral from 'numeral';
import { BorrowerPortalState } from '@services/borrower-portal/borrower-portal-state.service';
import { PortfolioRequest } from '@models/api/borrower-portal/portfolio-request';
import { PortfolioService } from '@services/borrower-portal/portfolio.service';
import { Lookups } from '@lookups/lookups';

@Component({
	selector: 'app-payment-form',
	templateUrl: './payment-form.component.html',
    styleUrls: ['./payment-form.component.scss']
}) 

export class PaymentFormComponent { 
	@Input() paymentRequest: PortfolioRequest;
	@ViewChild("creditCardNumberInput", { static: false }) creditCardNumberInput: ElementRef;
	@ViewChild("cvvInput", { static: false }) cvvInput: ElementRef;
	public cardType: string = '';
	public lookups: typeof Lookups = Lookups;
	private unknownCardType: string = 'unknown';
	private visa: string = 'visa';
	private mastercard: string = 'mastercard';
	private discover: string = 'discover';
	private amex: string = 'amex';

    constructor(public portfolioService: PortfolioService, 
		public portalState: BorrowerPortalState) {}

	public formatCurrency(amount: number | string): string {

        if(!amount)
        {
            return '';
		}
		
		return numeral(amount.toString()).format('$0,0.00');
	}

	public setCardType(): void {
		let cardNumber = this.portfolioService.payment.creditCardNumber;

		if (/^4[0-9]/.test(cardNumber)) {
			this.cardType = this.visa;
		} else if (/^5[1-5]/.test(cardNumber) || /^2[2-7]/.test(cardNumber)) {
			this.cardType = this.mastercard;		
		} else if (/^3[47]/.test(cardNumber)) {
			this.cardType = this.amex;
		} else if (/^6011/.test(cardNumber) ||
				   /^6[45]/.test(cardNumber) ||
			       /^62[24568]/.test(cardNumber)) {
			this.cardType = this.discover;
		} else {
			this.cardType = this.unknownCardType;
		}
			
		if (!this.cardType || this.cardType === this.unknownCardType) {
			this.creditCardNumberInput?.nativeElement?.classList.remove(this.visa, this.mastercard, this.discover, this.amex);
		} else {
			this.creditCardNumberInput?.nativeElement?.classList.add(this.cardType);
		}

		let cvv = this.portfolioService.payment?.cvv;
		let isUnknown = this.cardType === this.unknownCardType;
		let isAmex = this.cardType === this.amex;
		this.cvvInput.nativeElement.maxLength = isAmex || isUnknown ? 4 : 3;
		if (!isAmex && !isUnknown && cvv?.length > 3) {
			this.portfolioService.payment.cvv = '';
		}
	}

	public onFocusExpirationDateInput() {
		this.portfolioService.payment.expirationDate = '';
	}

	public validateExpirationDateInput(event: KeyboardEvent) {
		let inputElement = (event.target as HTMLInputElement);
		let inputValue = inputElement.value;
		let sanitizedInput = inputValue.replace(/\D/g, ''); // strip out non-numeric characters
		let currentYear = new Date().getFullYear() % 100;
		let inputYear = sanitizedInput?.length === 4 ? parseInt(sanitizedInput.slice(-2), 10) : currentYear;
		
		const regex = /^((0[1-9\s*]|1[0-2\s*])\/(\s*|\s*\s*|2[4-9\s*]|3[0-9\s*]|4[0-9\s*]))$/;
		if (!regex.test(inputValue) || inputYear < currentYear) {
			this.portfolioService.payment.expirationDate = '';
			inputElement.blur();
		}	
	}

	public hidePaymentRequestDialog() {
		if (this.portfolioService.isPaymentComplete) {
			this.paymentRequest = null;
		}
		this.portfolioService.hidePaymentMessages();
		this.portfolioService.resetPaymentForm();
		this.portfolioService.showPaymentDialog = false;
	}

	public isStatusMessageVisible() {
		return this.portfolioService.showProgressAnimation ||
			this.portfolioService.showPaymentFailureMessage ||
			this.portfolioService.showPaymentSuccessMessage;
	}
} 
