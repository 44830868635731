export const States: LoanApplicationStateList[] = [
	{
		StateId: 0,
		Name: '',
		StateCode: ''
	},
	{
		StateId: 1,
		Name: 'Alabama',
		StateCode: 'AL'
	},
	{
		StateId: 2,
		Name: 'Alaska',
		StateCode: 'AK'
	},
	{
		StateId: 3,
		Name: 'Arizona',
		StateCode: 'AZ'
	},
	{
		StateId: 4,
		Name: 'Arkansas',
		StateCode: 'AR'
	},
	{
		StateId: 5,
		Name: 'California',
		StateCode: 'CA'
	},
	{
		StateId: 6,
		Name: 'Colorado',
		StateCode: 'CO'
	},
	{
		StateId: 7,
		Name: 'Connecticut',
		StateCode: 'CT'
	},
	{
		StateId: 8,
		Name: 'Delaware',
		StateCode: 'DE'
	},
	{
		StateId: 9,
		Name: 'District of Columbia',
		StateCode: 'DC'
	},
	{
		StateId: 10,
		Name: 'Florida',
		StateCode: 'FL'
	},
	{
		StateId: 11,
		Name: 'Georgia',
		StateCode: 'GA'
	},
	{
		StateId: 12,
		Name: 'Hawaii',
		StateCode: 'HI'
	},
	{
		StateId: 13,
		Name: 'Idaho',
		StateCode: 'ID'
	},
	{
		StateId: 14,
		Name: 'Illinois',
		StateCode: 'IL'
	},
	{
		StateId: 15,
		Name: 'Indiana',
		StateCode: 'IN'
	},
	{
		StateId: 16,
		Name: 'Iowa',
		StateCode: 'IA'
	},
	{
		StateId: 17,
		Name: 'Kansas',
		StateCode: 'KS'
	},
	{
		StateId: 18,
		Name: 'Kentucky',
		StateCode: 'KY'
	},
	{
		StateId: 19,
		Name: 'Louisiana',
		StateCode: 'LA'
	},
	{
		StateId: 20,
		Name: 'Maine',
		StateCode: 'ME'
	},
	{
		StateId: 21,
		Name: 'Maryland',
		StateCode: 'MD'
	},
	{
		StateId: 22,
		Name: 'Massachusetts',
		StateCode: 'MA'
	},
	{
		StateId: 23,
		Name: 'Michigan',
		StateCode: 'MI'
	},
	{
		StateId: 24,
		Name: 'Minnesota',
		StateCode: 'MN'
	},
	{
		StateId: 25,
		Name: 'Mississippi',
		StateCode: 'MS'
	},
	{
		StateId: 26,
		Name: 'Missouri',
		StateCode: 'MO'
	},
	{
		StateId: 27,
		Name: 'Montana',
		StateCode: 'MT'
	},
	{
		StateId: 28,
		Name: 'Nebraska',
		StateCode: 'NE'
	},
	{
		StateId: 29,
		Name: 'Nevada',
		StateCode: 'NV'
	},
	{
		StateId: 30,
		Name: 'New Hampshire',
		StateCode: 'NH'
	},
	{
		StateId: 31,
		Name: 'New Jersey',
		StateCode: 'NJ'
	},
	{
		StateId: 32,
		Name: 'New Mexico',
		StateCode: 'NM'
	},
	{
		StateId: 33,
		Name: 'New York',
		StateCode: 'NY'
	},
	{
		StateId: 34,
		Name: 'North Carolina',
		StateCode: 'NC'
	},
	{
		StateId: 35,
		Name: 'North Dakota',
		StateCode: 'ND'
	},
	{
		StateId: 36,
		Name: 'Ohio',
		StateCode: 'OH'
	},
	{
		StateId: 37,
		Name: 'Oklahoma',
		StateCode: 'OK'
	},
	{
		StateId: 38,
		Name: 'Oregon',
		StateCode: 'OR'
	},
	{
		StateId: 39,
		Name: 'Pennsylvania',
		StateCode: 'PA'
	},
	{
		StateId: 40,
		Name: 'Rhode Island',
		StateCode: 'RI'
	},
	{
		StateId: 41,
		Name: 'South Carolina',
		StateCode: 'SC'
	},
	{
		StateId: 42,
		Name: 'South Dakota',
		StateCode: 'SD'
	},
	{
		StateId: 43,
		Name: 'Tennessee',
		StateCode: 'TN'
	},
	{
		StateId: 44,
		Name: 'Texas',
		StateCode: 'TX'
	},
	{
		StateId: 45,
		Name: 'Utah',
		StateCode: 'UT'
	},
	{
		StateId: 46,
		Name: 'Vermont',
		StateCode: 'VT'
	},
	{
		StateId: 47,
		Name: 'Virginia',
		StateCode: 'VA'
	},
	{
		StateId: 48,
		Name: 'Washington',
		StateCode: 'WA'
	},
	{
		StateId: 49,
		Name: 'West Virginia',
		StateCode: 'WV'
	},
	{
		StateId: 50,
		Name: 'Wisconsin',
		StateCode: 'WI'
	},
	{
		StateId: 51,
		Name: 'Wyoming',
		StateCode: 'WY'
	}
];

export interface LoanApplicationStateList {
	StateId: number;
	Name: string;
	StateCode: string;
}
