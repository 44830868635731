import _ from 'lodash';

export abstract class AdapterBase<TSource, TDestination> {
	public abstract adapt(source: TSource, options?: AdapterOptions): TDestination;

	public adaptWith(
		source: TSource,
		specializedAdapter: (source: TSource, destination: TDestination) => void | TDestination,
		options?: AdapterOptions
	): TDestination {
		const result = this.adapt(source, options);
		return specializedAdapter(source, result) || result;
	}

	public adaptCollection(sourceCollection: Array<TSource>, options?: CollectionAdapterOptions): Array<TDestination> {
		let result = _.map(sourceCollection, (sourceItem) => this.adapt(sourceItem, options));
		if (options && options.compact) {
			result = _.compact(result);
		}
		return result;
	}

	public adaptCollectionWith(
		sourceCollection: Array<TSource>,
		specializedAdapter: (source: TSource, destination: TDestination) => void | TDestination,
		options?: CollectionAdapterOptions
	): Array<TDestination> {
		let destResult = _.map(sourceCollection, (sourceItem) => {
			return this.adaptWith(sourceItem, specializedAdapter, options);
		});
		if (options && options.compact) {
			destResult = _.compact(destResult);
		}
		return destResult;
	}
}

export interface AdapterOptions {
	[key: string]: any;
}

export interface CollectionAdapterOptions extends AdapterOptions {
	compact?: boolean;
}
