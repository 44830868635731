
import { LinkedAccountFormToLinkedItemApiAdapter } from './linked-account-form-to-linked-item-api.adapter';
import { AdapterBase } from '@utilities/adapter-base';
import { LinkedAccountFormCollection } from '@models/form/linked-account-collection.model';
import { LinkedAccountApi } from '@models/api/linked-account';
import { LinkPropertiesApi } from '@models/api/link-properties';

export class LinkedAccountFormCollectionToLinkedAccountApiAdapter extends AdapterBase<LinkedAccountFormCollection, LinkedAccountApi> {
	private _linkedAccountFormToLinkedItemApiAdapter: LinkedAccountFormToLinkedItemApiAdapter = new LinkedAccountFormToLinkedItemApiAdapter();

	adapt(source: LinkedAccountFormCollection): LinkedAccountApi {
		const dest = new LinkedAccountApi();
		dest.LinkedAccountId = source.id;
		dest.AccountName = source.name;
		dest.AccountType = source.type;
		dest.LinkSourceId = source.sourceId;
		dest.LinkSource = source.source;
		dest.LinkStatus = source.status;
		dest.LinkedByUserId = source.linkedByUser;
		dest.DateLinked = source.dateLinked;
		dest.LinkedItems = this._linkedAccountFormToLinkedItemApiAdapter.adaptCollection(source.accounts);
		dest.LinkProperties = new LinkPropertiesApi();
		dest.LinkProperties.public_token = source.publicToken;
		dest.LinkProperties.day1_certainty_token = source.day1CertaintyToken;
		return dest;
	}
}
