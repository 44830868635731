import { AddressApi } from './address';

export class ResidenceApi {
	public Address: AddressApi;
	public MailingAddress: AddressApi;
	public PropertyOwned: boolean;
	public DurationTermMonths: number;
	public MailingAddressDifferent: boolean;
	public IsCurrent: boolean;
	public RentalAmount: number;
}
