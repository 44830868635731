import { Component, OnDestroy } from '@angular/core';
import { ErrorService } from '@services/error.service';
import { Router } from '@angular/router';
import { ConfigService } from '@services/config.service';

@Component({
	selector: 'app-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnDestroy {
	public message: string =
		(this.errorService.currentErrorState && this.errorService.currentErrorState.message) ||
		this.errorService.defaultMessage;

	public title: string =
		(this.errorService.currentErrorState && this.errorService.currentErrorState.title) ||
		this.errorService.defaultTitle;

	public retryText: string =
		(this.errorService.currentErrorState && this.errorService.currentErrorState.retryText) ||
		this.errorService.defaultRetryText;

	public retryAction: (...params: any) => { } =
		(this.errorService.currentErrorState && this.errorService.currentErrorState.retryAction) ||
		this.errorService.defaultRetryAction;

	public debugDetails: any;
	public debugDetailsFormatted: string;

	constructor(public errorService: ErrorService, public router: Router, public config: ConfigService) {
		if (this.errorService.currentErrorState === null) {
			this.router.navigate(['/status']);
			return;
		}

		if (!('toJSON' in Error.prototype)) {
			Object.defineProperty(Error.prototype, 'toJSON', {
				value: () => {
					const alt = { };

					Object.getOwnPropertyNames(this).forEach((key) => {
						alt[key] = this[key];
					}, this);

					return alt;
				},
				configurable: true,
				writable: true
			});
		}

		this.debugDetails =
			this.errorService.currentErrorState.debugDetails &&
				this.errorService.currentErrorState.debugDetails.length > 0
				? this.errorService.currentErrorState.debugDetails
				: null;

		if (this.debugDetails) {
			try {
				this.debugDetailsFormatted = JSON.stringify(this.debugDetails, null, 4);
			} catch (e) {
				if (this.config.DEBUG === 'true') {
					// eslint-disable-next-line no-console
					console.warn("I couldn't write the message to JSON, so I'm placing it here instead");
					// eslint-disable-next-line no-console
					console.error('This is the real problem:', this.debugDetails);
					// eslint-disable-next-line no-console
					console.warn('This is what happened when I tried to write it to JSON:', e);
					this.debugDetailsFormatted =
						'There was an issue putting the error on the screen. Please check your console.';
				}
			}
		}
	}

	ngOnDestroy(): void {
		this.errorService.dismiss();
	}
}
