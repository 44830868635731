import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import _ from 'lodash';
import { Tracker } from '@nbkc/tracker-angular';
import { ConfigService } from './config.service';
import { GeneralError } from '../tracking/errors/general.error';

@Injectable({
	providedIn: 'root'
})
export class ErrorService {
	public defaultDelay: number = 0;
	public defaultDisplay: string = 'error-page';
	public defaultMessage: string = 'We\'re experiencing some unexpected issues right now.';
	public defaultRetryAction: any = null;
	public defaultRetryText: string = 'Retry';
	public defaultTitle: string = 'Oops!';
	public currentErrorState: ApplicationErrorState = null;
	private snacky: MatSnackBarRef<SimpleSnackBar>;

	constructor(
		public config: ConfigService,
		public router: Router,
		public snackBar: MatSnackBar,
		public tracker: Tracker
	) {
	}

	public handle(options: any): void {
		const filledOptions: any = {
			data: options.data || null,
			debugDetails: _.compact([options.debugDetails || null, options.data || null]),
			display: options.display || this.defaultDisplay,
			message: options.message || this.defaultMessage,
			redirectOnProd: options.redirectOnProd || null,
			title: options.title || this.defaultTitle,
			retryText: options.retryText || this.defaultRetryText,
			retryAction: options.retryAction || this.defaultRetryAction,
			success: options.success || false,
			delay: options.delay || this.defaultDelay,
			context: options.context || null
		};

		if (filledOptions.data && !filledOptions.success) {
			this.tracker.error(new GeneralError(new Error(JSON.stringify({
				data: JSON.stringify(filledOptions.data),
				display: filledOptions.display,
				message: filledOptions.message,
				retryText: filledOptions.retryText,
				redirect: filledOptions.redirectOnProd
			}))));
		}

		if (filledOptions.redirectOnProd && this.config.DEBUG === 'false') {
			this.redirectUserToSafety(filledOptions);
		} else {
			this.showErrorMessageOnDisplay(filledOptions);
		}
	}

	public redirectUserToSafety(filledOptions: any): void {
		if (filledOptions.redirectOnProd) {
			this.router.navigate(filledOptions.redirectOnProd);
		}
	}

	public showErrorMessageOnDisplay(filledOptions: any): void {
		if (filledOptions.display === 'error-page') {
			this.currentErrorState = {
				debugDetails: filledOptions.debugDetails,
				isReloading: false,
				title: filledOptions.title,
				message: filledOptions.message,
				retryText: filledOptions.retryText,
				retryAction: filledOptions.retryAction
			};
			this.router.navigate(['/error']);
		} else if (filledOptions.display === 'toast') {
			const toastClass = (filledOptions.success) ? 'nbkc-success' : 'nbkc-error';
			this.snacky = this.snackBar.open(filledOptions.message, filledOptions.retryText.toUpperCase(), {
				duration: filledOptions.delay,
				horizontalPosition: 'right',
				verticalPosition: 'bottom',
				panelClass: toastClass
			});

			this.snacky.onAction().subscribe(() => {
				if (filledOptions.retryAction) {
					filledOptions.retryAction();
				}
			});
		} else {
			// eslint-disable-next-line no-console
			console.log('Error: ' + filledOptions.message + '\n\n' + filledOptions.data);
		}
	}

	public dismiss(): void {
		if (this.snacky) {
			this.snacky.dismiss();
		}
	}
}

interface ApplicationErrorState {
	debugDetails: any;
	isReloading: boolean;
	title: string;
	message: string;
	retryText: string;
	retryAction: () => void;
}
