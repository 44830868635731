import {
	TrackerEvent,
	ApplicationInsightsProviderMapping,
	ApplicationInsightsEvent
} from '@nbkc/tracker';
import { ApplicationTrackerContext } from '../application-tracker-context';

export class PreApprovalButtonClickEvent extends TrackerEvent<ApplicationTrackerContext>
	implements ApplicationInsightsProviderMapping {
	public name: string = 'preapproval-button-click';

	public constructor(public data: PreApprovalButtonClickEventProps) {
		super();
	}

	public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = this.name;
		event.data = {
			applicationId: this.trackerContext.applicationId,
			loanId: this.data.loanId,
			salesPrice: this.data.salesPrice,
			loanToValue: this.data.loanToValue,
			loanAmount: this.data.loanAmount
		};

		return event;
	}
}

interface PreApprovalButtonClickEventProps {
	loanId: string;
	salesPrice: string;
	loanToValue: string;
	loanAmount: string;
}
