export class LoanOfficerSummaryApi {
	public UserId: string;
	public DisplayName: string;
	public EmailAddress: string;
	public WorkPhone: string;
	public MobilePhone: string;
	public FaxNumber: string;
	public PhotoUrl: string;
	public NmlsNumber: number;
	public ApplyUrl: string;
}
