import { Subject, Observable, ReplaySubject } from 'rxjs';
import { throttleTime, first } from 'rxjs/operators';
import { async } from 'rxjs/internal/scheduler/async';

export class SaveThrottle {
	/**
	 * Emits an event any time an application save is completed.
	 */
	private onApplicationSave: ReplaySubject<any>;

	/**
	 * Subject for running save operations.
	 */
	private readonly onRequestApplicationSave: Subject<boolean> = new Subject<boolean>();

	private throttleTime: number;

	constructor(private throttledFunction: (defaultErrorHandling: any) => Observable<any>, myThrottleTime: number = 200) {
		this.throttleTime = myThrottleTime;
		this.onApplicationSave = new ReplaySubject();
		this.buildThrottle();
	}

	/**
	 * Responsible for emitting an event that requests an application save to be completed.
	 */
	public requestSave(defaultErrorHandling: boolean = true): Observable<any> {
		const currentOnApplicationSave = this.onApplicationSave;
		this.onRequestApplicationSave.next(defaultErrorHandling);
		return currentOnApplicationSave.pipe(first());
	}

	private buildThrottle(): void {
		this.onRequestApplicationSave.pipe(
			throttleTime(this.throttleTime, async, {
				leading: true,
				trailing: true
			})).subscribe((defaultErrorHandling) => {
				const context = this.onApplicationSave;
				this.onApplicationSave = new ReplaySubject();
				this.throttledFunction(defaultErrorHandling).subscribe((response) => {
					context.next(response);
					context.complete();
				}, (error) => {
					context.error(error);
					/** Must rebuild subject. It is not usable once error() is called. */
					context.unsubscribe();
				});
			});
	}
}
