import {
	Component, EventEmitter, OnInit, Input, Output,
	ElementRef,
	ViewChild
} from '@angular/core';

@Component({
	selector: 'app-combo-box',
	templateUrl: './combo-box.component.html',
	styleUrls: ['./combo-box.component.css']
})
export class ComboBoxComponent implements OnInit {

	@Input() public list: string[];
	@Input() public currentInput: string; // used to reset input text with data from model when reinitializing the component
	@Input() public required: boolean = false;
	@Input() public disabled: boolean = false;
	@Input() public showError: boolean = false;
	@Input() public accessibilityLabel: string;
	@Output() public selection: EventEmitter<string> = new EventEmitter<string>();
	public inputText: string = '';
	public listHidden: boolean = true;
	public selectedIndex: number = -1;
	public filteredList: string[] = [];
	public focusedItem: number = -1;
	public accessibilityLabelText: string = '';
	public comboBoxFocused: boolean = false;
	@ViewChild('comboBoxInput') private inputBox: ElementRef; 

	public ngOnInit(): void {
		this.filteredList = this.list.sort();
		this.inputText = this.currentInput;
		this.accessibilityLabelText = this.accessibilityLabel;
	}

	public getFilteredList(): void {

		this.listHidden = false;
		this.focusedItem = -1;
		this.selectedIndex = -1;
		if (!this.listHidden && this.inputText) {
			this.filteredList = this.list.filter((item) => item?.toLowerCase().includes(this.inputText.toLowerCase()));
		}
	}

	public selectItem(index: number): void {
		if(!index || index < 0){
			return;
		}
		this.inputText= this.filteredList[index];
		this.listHidden = true;
		this.selectedIndex = index;
		this.selection.emit(this.inputText);
	}

	public onKeyPress(event: KeyboardEvent): void {

		if (!this.listHidden) {
			if (event.key === 'Escape') {
				this.selectedIndex = -1;
				this.toggleListDisplay(0);
			}

			if (event.key === 'Enter') {

				this.toggleListDisplay(0);
			}
			if (event.key === 'ArrowDown') {
				this.listHidden = false;
				if (this.focusedItem < this.filteredList.length) {
					this.selectedIndex = (this.selectedIndex + 1);
					if (this.filteredList.length > 0 && !this.listHidden) {
						this.setFocusedIndex(this.selectedIndex);
						document.getElementsByClassName('list-item')[this.focusedItem]?.scrollIntoView();
					}
				}

			} else if (event.key === 'ArrowUp') {

				this.listHidden = false;
				if (this.focusedItem >= 0) {
					this.selectedIndex = (this.selectedIndex - 1);
					if (this.filteredList.length > 0 && !this.listHidden) {
						this.setFocusedIndex(this.selectedIndex);
						document.getElementsByClassName('list-item')[this.focusedItem]?.scrollIntoView();
					}
				}

			}
		}
	}

	public handleComboBoxPointerDown(event: any): void{
		this.comboBoxFocused = true;
	}

	public handleComboBoxPointerUp(event: any): void{
		this.comboBoxFocused = false; 
		this.inputBox.nativeElement.focus(); 
	}

	public handleMouseover(event: any): void {
		const targetIndex = this.filteredList.indexOf(event.target.innerText);
		this.setFocusedIndex(targetIndex);
	}

	public handleMouseOut(event: any): void {
		this.setFocusedIndex(-1);
	}

	
	public toggleListDisplay(sender: number): void {
		if (sender === 1 || this.comboBoxFocused) {
			// this.selectedIndex = -1;
			this.listHidden = false;
			this.getFilteredList();
		} else {
			// helps to select item by clicking
			setTimeout(() => {
				this.selectItem(this.selectedIndex);
				this.listHidden = true;
				if (!this.list?.includes(this.inputText) && this.required) {
					this.showError = true;
					this.filteredList = this.list;
				} else {
					this.showError = false;
				}
			}, 500);
		}
	}

	private setFocusedIndex(num: number): void {
		if (Number.isNaN(Number(num)) || num < -1) {
			return;
		}
		this.focusedItem = num;
		Array.from(document.getElementsByClassName('list-item')).forEach((element) => {
			element.classList.remove('focused');
		}); ;
		document.getElementsByClassName('list-item')[this.focusedItem]?.classList.add('focused');
	}
}
