import {
	ApplicationInsightsProviderMapping,
	TrackerEvent,
	ConsoleTrackingProvider,
	ApplicationInsightsTrackingProvider,
	ApplicationInsightsEvent
} from '@nbkc/tracker';
import { ApplicationTrackerContext } from '../application-tracker-context';
import { TrackingProvider } from '@nbkc/tracker/dist/providers/tracking-provider';

export class ConfigurationLoadedEvent extends TrackerEvent<ApplicationTrackerContext>
	implements ApplicationInsightsProviderMapping {
	public name: string = 'configuration-loaded';
	public providers: typeof TrackingProvider[] = [ConsoleTrackingProvider, ApplicationInsightsTrackingProvider];

	public constructor(public data: ConfigurationLoadedEventProps) {
		super();
	}

	public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = this.name;
		event.data = {
			config: this.data.config
		};
		return event;
	}
}

interface ConfigurationLoadedEventProps {
	config: any;
}
