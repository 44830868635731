import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { ApplicationView } from '@models/application-view.model';

@Component({
	selector: 'app-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
	public mostRecentApplication: ApplicationView;

	constructor(public activeModal: NgbActiveModal) { }

	public applicationIsCreatedText(): string {
		return `You created an application on ${moment(this.mostRecentApplication.CreatedDt as any).format('dddd, MMMM Do')}`;
	}

	public applicationIsSubmittedText(): string {
		return `You submitted an application on ${moment(this.mostRecentApplication.SubmittedDt as any).format('dddd, MMMM Do')}`;
	}

	public continue(): void {
		this.activeModal.close({ });
	}

	public close(): void {
		this.activeModal.dismiss({ });
	}

	public goToApplication(): void {
		this.activeModal.dismiss({
			goToApplication: true
		});
	}

	public goToDocumentUploads(): void {
		this.activeModal.dismiss({ goToDocumentUploads: true });
	}

}
