import { Validatable, Validate, Validators } from '@nbkc/validation';
import { LineItem } from '@models/api/borrower-portal/line-item';

export class PaymentForm extends Validatable {
	public validationEnabled: boolean = true;
	@Validate(Validators.required('Please enter the name as it appears on your card.'))
	public nameOnCard: string = '';
	@Validate(Validators.creditCard("Please enter a valid credit card number."))
	public creditCardNumber: string = '';
	@Validate(Validators.minLength(3, 'Please enter a cvv code.'))	
	public cvv: string = '';
	@Validate(Validators.expirationDate('MM/YY', 'month', 'Please enter a valid expiration date.'))
	public expirationDate: string = ''; 
	@Validate(Validators.zip('Please enter a 5 digit zip code.'))
	public zipCode: string = ''; 	
	public disclaimer: string = 'I authorize nbkc bank to charge my credit card for the stated amount. Providing my credit card information is my agreement to payment of fee(s) as itemized. A revised disclosure will be provided as required for revisions or updated fees.';
	public amount: number = 0;
	public lineItems: LineItem[];
	public requestId: string;
	public loanId: string; 

	constructor(props: any = { }) {
		super();
	}
}
