import { Validatable, Validate, Validators } from '@nbkc/validation';

export class DemographicAnswer<TValue> extends Validatable {
	@Validate(Validators.isTruthy())
	public value: TValue;
	public touched: boolean;

	constructor(value: TValue) {
		super();
		this.value = value;
	}
}
