import { Tracker } from '@nbkc/tracker-angular';
import { PageHiddenEvent, PageVisibilityNotSupportedEvent, PageVisibleEvent } from '../tracking/events';

export class VisiblityMonitor {
	public static start(tracker: Tracker): void {
		let hidden;
		let visibilityChange;

		if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
			hidden = 'hidden';
			visibilityChange = 'visibilitychange';
		} else if (typeof (document as any).msHidden !== 'undefined') {
			hidden = 'msHidden';
			visibilityChange = 'msvisibilitychange';
		} else if (typeof (document as any).webkitHidden !== 'undefined') {
			hidden = 'webkitHidden';
			visibilityChange = 'webkitvisibilitychange';
		}

		if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
			tracker.event(new PageVisibilityNotSupportedEvent());
			return;
		}

		const handleVisibilityChange = () => {
			if (document[hidden]) {
				tracker.event(new PageHiddenEvent());
			} else {
				tracker.event(new PageVisibleEvent());
			}
		};

		document.addEventListener(visibilityChange, handleVisibilityChange, false);
	}
}
