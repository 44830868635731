import { Component } from '@angular/core';
import { CompatibilityFixes } from '@utilities/compatibility/compatibility-fixes';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { Router, NavigationEnd } from '@angular/router';
import { SectionService } from '@services/section.service';
import { ErrorService } from '@services/error.service';
import { ApplicationService } from '@services/application.service';
import { AppState } from '@services/app-state.service';
import _ from 'lodash';
import { Section } from '@models/section.model';
import { Sections } from '@lookups/sections';

@Component({
	selector: 'app-app-header',
	templateUrl: './app-header.component.html',
	styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent {
	public userCircleCollapsed: boolean = true;
	public animateUserCircle: boolean = false;
	public view: string = 'customer';
	public dismissOldBrowser: boolean = false;
	public dismissedEnv: boolean = false;
	public isOnLongForm: boolean = false;
	public isOnProfile: boolean = _.includes(this.router.url.split('/'), 'profile');
	public navSections: Section[] = _.filter(Sections, (section) => !section.hiddenFromNavigation && !section.disabled);

	private isOldBrowser: boolean = false;
	private checkedOldBrowser: boolean = false;

	get reviewed(): boolean {
		if (
			this.applicationService.currentApplication &&
			this.applicationService.currentApplication.progress &&
			this.applicationService.currentApplication.progress
		) {
			return this.applicationService.currentApplication.progress.reviewed;
		} else {
			return false;
		}
	}

	public get oldBrowser(): boolean {
		if (!this.checkedOldBrowser) {
			this.checkedOldBrowser = true;
			this.isOldBrowser = CompatibilityFixes.isOldBrowser();
		}
		return this.isOldBrowser;
	}

	constructor(
		public appState: AppState,
		public applicationService: ApplicationService,
		public authService: AuthService,
		public errorService: ErrorService,
		public sectionService: SectionService,
		public userService: UserService,
		public router: Router
	) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				// event is an instance of NavigationEnd, get url!
				const url = event.urlAfterRedirects.split('/');
				this.isOnLongForm = _.includes(url, 'apply');
				this.isOnProfile = _.includes(url, 'profile');
			}
		});
	}

	public navigateSection(sectionName: string): void {
		this.userCircleCollapsed = true;
	}

	public toggleUserCircle(): void {
		this.userCircleCollapsed = !this.userCircleCollapsed;
		this.animateUserCircle = true;
		setTimeout(() => {
			this.animateUserCircle = false;
		}, 1000);
	}

	public navHome(): void {
		this.router.navigate(['']);
	}

	public onMenuClick(menuItem: string): void {
		this.userCircleCollapsed = true;
		switch (menuItem) {
			case 'myApplications':
				this.router.navigate(['']);
				break;
			case 'signOut':
				this.authService.logout();
				break;
			case 'signIn':
				this.authService.login();
				break;
		}
	}

	public userCircleCollapser(): void {
		this.userCircleCollapsed = !this.userCircleCollapsed;
	}
}
