import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Tracker } from '@nbkc/tracker-angular';
import { ClickEvent } from '../tracking/events';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[click][id], [click][clickTracker][id], [id][href], [superClick][id], [superClick][clickTracker][id]'
})
export class ClickTrackerDirective {
	@Input() clickTracker: () => void;
	@Input() disableTracker: boolean;

	constructor(public el: ElementRef, private tracker: Tracker) { }

	@HostListener('click')
	public onClick(): void {
		try {
			if (!this.disableTracker) {
				if (this.clickTracker) {
					this.clickTracker();
				} else {
					this.tracker.event(new ClickEvent({
						targetElement: this.el
					}));
				}
			}
		} catch (error) {

		}
	}
}
