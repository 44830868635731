import {
	TrackerError,
	ApplicationInsightsProviderMapping,
	ApplicationInsightsError
} from '@nbkc/tracker';
import { ApplicationTrackerContext } from '../application-tracker-context';

export class PaymentSubmissionError extends TrackerError<ApplicationTrackerContext>
	implements ApplicationInsightsProviderMapping {
	public name: string = 'payment-submission-error';

	public ai(): ApplicationInsightsError {
		const error = new ApplicationInsightsError();
		error.name = this.name;
		error.error = this.error;
		return error;
	}
}
