import { PrimaryLeadSourceApi } from './primary-lead-source';

export class LoanDetailsApi {
	public RateType: string;
	public LoanPurpose: string;
	public PrimaryLeadSource: PrimaryLeadSourceApi;
	public DownPayment: number;
	public LoanAmount: number;
	public LoanType: string;
	public Rate: number;
	public Apr: number;
	public Term: number;
	public PrincipalAndInterestPaymentAmount: number;
	public TotalLenderFees: number;
	public DiscountPoints: number;
	public CreditRating: string;
}
