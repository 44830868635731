
import { DemographicAnswer } from '@models/form/demographics/demographic-helpers/demographic-answer.model';
import { Validate, Validators } from '@nbkc/validation';
import { DemographicSection } from './demographic-section.model';

export class DemographicsEthnicity extends DemographicSection {
	@Validate(Validators.required())
	public hispanicOrLatino: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public mexican: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public puertoRican: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public cuban: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public otherHispanicOrLatino: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public notHispanicOrLatino: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public otherEthnicityFreeResponse: DemographicAnswer<string> = new DemographicAnswer('');
	@Validate(Validators.required())
	public noEthnicity: DemographicAnswer<boolean> = new DemographicAnswer(false);

	constructor() {
		super();
	}
}
