export class Document {
    public Id: string;
    public Name: string;
    public Filename: string;
    public Extension: string;
    public ContentType: string;
    public CreatedDate: string;
    public TransferStatus: string;
    public ThisCardsIndex: number;
    public Completed: boolean;
    public PercentComplete: number;
    public ErrorMessage: string;
    public HasError: boolean;
    public FormData: FormData = new FormData();
    public Started: boolean;
    public Progressed: boolean;
    public ProgressInterval: number;
    public IsDisplayed: boolean;
}