import { IdDescriptionPair } from '@lookups/lookups';
import _ from 'lodash';

export abstract class Lookable {
	public static getItem<T = IdDescriptionPair>(lookupCollection: T[], id: string | number): T {
		return _.find(lookupCollection, (value: any) => value.id === id);
	}

	public static getItemByValue<T = IdDescriptionPair>(lookupCollection: T[], targetValue: string | number): T {
		return _.find(lookupCollection, (value: any) => value.value === targetValue);
	}

	public static getItemByDescription<T = IdDescriptionPair>(lookupCollection: T[], description: string | number): T {
		return _.find(lookupCollection, (value: any) => value.description === description);
	}
}
