import { PhoneApi } from './phone';
import { EmailApi } from './email';
import { DeclarationsApi } from './declarations';
import { EmployerApi } from './employer';
import { LiabilityApi } from './liability';
import { ResidenceApi } from './residence';
import { DemographicApi } from './demographic';
import { IncomeSourceApi } from './income-source';
import { MilitaryServiceApi } from './military-service';

export class BorrowerApi {
	public BorrowerIndex: number;
	public FirstName: string;
	public MiddleName: string;
	public LastName: string;
	public Suffix: string;
	public TaxIdentificationIdentifier: string | null; // this is a fancy word for ssn
	public BirthDate: string;
	public MaritalStatus: string | null;
	public DependentCount: number;
	public DependentAges: string | null;
	public HaveEmployers: boolean;
	public Phones: PhoneApi[] | null;
	public Emails: EmailApi[] | null;
	public Declarations: DeclarationsApi | null;
	public Employers: EmployerApi[] | null;
	public Liabilities: LiabilityApi[] | null;
	public Residences: ResidenceApi[] | null;
	public Demographics: DemographicApi[] | null;
	public NonEmploymentIncome: IncomeSourceApi[] | null;
	public MilitaryService: MilitaryServiceApi | null;

	public constructor(props?: BorrowerApiProps) {
		if (props) {
			this.BorrowerIndex = props.BorrowerIndex;
		}
	}
}

interface BorrowerApiProps {
	BorrowerIndex?: number | null;
}
