export class PhoneApi {
	public IsPrimary: boolean;
	public Number: string;
	public PhoneType: string;

	constructor(phoneNumber: string, primary: boolean) {
		if (phoneNumber) {
			this.Number = phoneNumber;
		}
		this.IsPrimary = primary;
	}
}
