import { LinkedItemApi } from './linked-item';
import { LinkPropertiesApi } from './link-properties';

export class LinkedAccountApi {
	AccountName: string;
	AccountType: string
	LinkSource: string;
	LinkSourceId: string;
	LinkedAccountId: string;
	LinkedItems: LinkedItemApi[];
	LinkProperties: LinkPropertiesApi;
	LinkStatus: string;
	LinkedByUserId: string;
	DateLinked: string;
	DateUpdated: string;
}
