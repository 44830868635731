import { AdapterBase, AdapterOptions } from '@utilities/adapter-base';
import { LoanForm } from '@models/form/loan.model';
import { SubjectPropertyApi } from '@models/api/subject-property';
import numeral from 'numeral';

export class LoanFormToSubjectPropertyApiAdapter extends AdapterBase<LoanForm, SubjectPropertyApi> {
	public adapt(source: LoanForm): SubjectPropertyApi {
		const subjectProperty = new SubjectPropertyApi();
		subjectProperty.EstimatedValue = numeral(source.estimatedValueOrPurchasePrice).value();
		subjectProperty.PropertyType = source.occupancyType;
		if (source.propertyAddress) {
			subjectProperty.Address1 = source.propertyAddress.address1;
			subjectProperty.City = source.propertyAddress.city;
			subjectProperty.StateAbbreviation = source.state ? source.state : source.propertyAddress.state;
			subjectProperty.PostalCode = source.propertyAddress.zip;
			subjectProperty.UnitType = source.propertyAddress.unitType;
			subjectProperty.UnitNumber = source.propertyAddress.unitNumber;
			subjectProperty.County = source.propertyAddress.county;
			subjectProperty.CountryCode = source.propertyAddress.countryCode;
			subjectProperty.FirstLienBalance = numeral(source.outstandingMortgage).value()
		}
		return subjectProperty;
	}
}
