import { AdapterBase } from '@utilities/adapter-base';
import { UserProfile } from '@models/user-profile.model';
import { ProfileApi } from '@models/api/profile.model';
import moment from 'moment';
import _ from 'lodash';

export class UserProfileToProfileApiAdapter extends AdapterBase<UserProfile, ProfileApi> {
	public adapt(source: UserProfile): ProfileApi {
		const profile = new ProfileApi();
		profile.FirstName = source.FirstName;
		profile.LastName = source.LastName;
		profile.PrimaryPhone = source.PrimaryPhone;
		profile.EmailAddress = source.EmailAddress;
		profile.Roles = source.Roles;
		const attributeMap = new Map<string, string>();
		profile.Attributes = this.convertMapsToObjects(attributeMap);
		return profile;
	}

	private convertMapsToObjects(map: Map<string, string>): any {
		return _.reduce(Array.from(map), (obj, [key, value]) => {
			obj[key] = value;
			return obj;
		}, { });
	}
}
