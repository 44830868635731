import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Lookups } from '@lookups/lookups';
import { PortfolioRequestForm } from '@models/form/borrower-portal/portfolio-request-form.model';
import { BorrowerPortalCalculationService } from '@services/borrower-portal/borrower-portal-calculation.service';
import { BorrowerPortalState } from '@services/borrower-portal/borrower-portal-state.service';
import { PortfolioService } from '@services/borrower-portal/portfolio.service';
import { ErrorService } from '@services/error.service';



@Component({
	selector: 'app-pre-approval-letter-form',
	templateUrl: './pre-approval-letter-form.component.html',
	styleUrls: ['./pre-approval-letter-form.component.scss'],
})
export class PreApprovalFormComponent implements OnInit {
	@Input() preApprovalRequest: PortfolioRequestForm;
	@Output() buttonDisabledEvent = new EventEmitter<boolean>();
	public lookups: typeof Lookups = Lookups;
	public cardTitle: string;
	public cardContent: string;
	public documentDownloading: boolean = false;
	public maxLoanToValue: number;
	public maxLoanAmount: number;

	constructor(
		public portalState: BorrowerPortalState, 
		public portfolioService: PortfolioService,
		public errorService: ErrorService,
		public borrowerPortalCalculationService: BorrowerPortalCalculationService
		) {
		if (this.portalState.preApprovalFormShown === undefined) {
			this.portalState.preApprovalFormShown = false;
		}

	}
	ngOnInit(): void {
		if (!this.preApprovalRequest) {
			return;
		}
		this.buttonDisabledEvent.emit(false);
		this.cardTitle = this.preApprovalRequest.displayName;
		this.cardContent = this.preApprovalRequest.description;
		if(!!this.preApprovalRequest.detail){
			this.portfolioService.preApprovalLetter.loanToValue = parseInt((Math.floor(this.preApprovalRequest.detail.MaxLoanToValue)).toFixed());
			this.portfolioService.preApprovalLetter.loanAmount = this.preApprovalRequest.detail.MaxLoanAmount;
			this.portfolioService.preApprovalLetter.salesPrice = this.preApprovalRequest.detail.PurchasePrice.toString();
			this.maxLoanToValue = parseInt((Math.floor(this.preApprovalRequest.detail.MaxLoanToValue)).toFixed());
			this.maxLoanAmount = this.preApprovalRequest.detail.MaxLoanAmount;
		}
		// Sales price is an optional field in encompass. Set default value if one is not provided by the API
		if (!this.portfolioService.preApprovalLetter.salesPrice || this.portfolioService.preApprovalLetter.salesPrice == "0") {
			this.portfolioService.preApprovalLetter.salesPrice = (this.portfolioService.preApprovalLetter.loanAmount * (this.portfolioService.preApprovalLetter.loanToValue / 100)).toString();
		}
		this.getCalculatedValues();
	}

	public toggleFormDisplay(): void {
		this.portalState.preApprovalFormShown = !this.portalState
			.preApprovalFormShown;
		if(!this.portalState.preApprovalFormShown){
			this.resetForm();
		}
	}

	private resetForm(){
		this.portfolioService.preApprovalLetter.loanToValue = parseInt((Math.floor(this.preApprovalRequest.detail.MaxLoanToValue)).toFixed());
		this.portfolioService.preApprovalLetter.loanAmount = this.preApprovalRequest.detail.MaxLoanAmount;
		this.portfolioService.preApprovalLetter.salesPrice = this.preApprovalRequest.detail.PurchasePrice.toString();
		this.getCalculatedValues();
		this.buttonDisabledEvent.emit(false);

	}

	

	public getCalculatedValues() {
		const salesPriceInt = parseInt(this.portfolioService.preApprovalLetter.salesPrice.replace('$', '').replace(/,/g, ''));
		if (salesPriceInt > 0) {
			this.buttonDisabledEvent.emit(false);
			this.portfolioService.preApprovalLetter.loanToValue = this.borrowerPortalCalculationService.getCalculatedLoanToValue(salesPriceInt, this.maxLoanToValue, this.maxLoanAmount);
			this.portfolioService.preApprovalLetter.loanAmount = this.borrowerPortalCalculationService.getCalculatedLoanAmount(salesPriceInt, this.maxLoanToValue, this.maxLoanAmount);
		}else{
			this.buttonDisabledEvent.emit(true);
		}
	}

}
