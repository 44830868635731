import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FileBatch } from '../models/file-batch.model';

@Component({
	selector: 'app-document-upload-progress-bar',
	templateUrl: './document-upload-progress-bar.component.html',
	styleUrls: ['./document-upload-progress-bar.component.scss']
})
export class DocumentUploadProgressBarComponent implements OnInit, OnDestroy {

	@Input() fileBatch: FileBatch;
	@Output() progressComplete: EventEmitter<FileBatch> = new EventEmitter();

	private timeoutIncrement: number = 5000;
	private completeTimeoutIncrement: number = 1500; 
	private timeoutInterval: number = this.timeoutIncrement/20; 
	private progressInterval: ReturnType<typeof setInterval>;


	ngOnInit() {
		this.progressInterval = setInterval(() =>{
			if (this.fileBatch.IsComplete && !this.fileBatch.Files.find(x => !x.HasError)) {
				this.progressComplete.emit(this.fileBatch);
			}
			
			if(this.fileBatch.CompletionPercent !== 100) {
				this.fileBatch.CompletionPercent = this.uploadPercentageCalculation(this.fileBatch.IsComplete, this.fileBatch.CompletionPercent); 
			}
			else if (this.fileBatch.CompletionPercent === 100 && this.fileBatch.IsComplete) {
				setInterval(() => {
					this.progressComplete.emit(this.fileBatch);
				}, this.completeTimeoutIncrement)
			}
		}, this.timeoutInterval);

	}

	//As we approach 100% lower the amount we add to the current percentage, clamp at 100 when complete, and 99 when incomplete. 
	private uploadPercentageCalculation(isComplete : boolean, current : number, additive: number = 5, 
		randomMin: number = 0.1, randomMax: number = 2, expediationFactor : number = 2) : number {
		let expediate = 1; //Multiply the additive calculation by this factor to expediate the process when the document upload is complete.
		
		if(isComplete) {
			expediate = expediationFactor;
		}

		let randomness = Math.floor(Math.random() * ((randomMax - randomMin + 1) + randomMin)); //Add a bit of wiggle room to make the upload feel more organic.

		let calculation: number = (current + ((additive * (1-(current/100))) * expediationFactor)) + randomness;

		if(isComplete && calculation >= 95) {
			return 100; 
		}else if(calculation >= 99) {
			return 99; 
		}

		return Math.round(calculation);
	}

	ngOnDestroy() {
		clearInterval(this.progressInterval);
	}

}
