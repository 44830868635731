import { DemographicAnswer } from '@models/form/demographics/demographic-helpers/demographic-answer.model';
import { Validatable } from '@nbkc/validation';
import _ from 'lodash';

export class DemographicSection extends Validatable {
	constructor() {
		super();
	}

	public oneChecked(): boolean {
		return _.some(this.internalValues, (prop: DemographicAnswer<any>, key: string) => {
			return !!prop.value;
		});
	}
}
